import React from 'react'
import FeatherIcon from 'feather-icons-react'

export default function CyberGridComponent() {
  return (
    <>
      <div className="container ">
        <div className="row">
          <div className="col-md-6 mb-5">
            <iframe
              width="100%"
              height="115%"
              src="https://www.youtube.com/embed/tkMWG6X8yxo"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div className="col-md-6">
            <h2 className="text-dark">Meet Herbie AI chatbot</h2>
            <p className="mb-3 text-dark">
              Artificially Intelligent Virtual Assistant with Natural Language
              Processing & Machine Learning capabilities Serving your Visitors &
              Customers in a charming way and serves their needs tirelessly 24 x
              7 x 365 Complete Customer Experience with integrated approach –
              Human Web Chat, Virtual Assistant – Voice & text
            </p>
            <p>
              <a
                href={require('../products/Herbie.pdf')}
                download=""
                type="button"
                className="btn btn-success btn-hover-white pb-3"
                style={{borderRadius: 0}}
              >
                Read more about Herbie.ai{' '}
                <FeatherIcon icon="download" className="pt-2" />
              </a>
            </p>
          </div>
        </div>
      </div>

      <div className="container mt-5 pt-3">
        <div className="row">
          <div className="col-lg-4 col-12 col-sm-12  mb-3">
            <h2 className="text-dark">About Herbie.ai</h2>
            <p className="mb-3 text-dark">
              Complete Customer Experience with integrated approach – Human Web
              Chat, Virtual Assistant – Voice & text
            </p>
          </div>
          <div className="col-lg-4 col-12 col-sm-12  mb-3">
            <h2 className="text-dark">Herbie across industries</h2>
            <p className="mb-3 text-dark">
              The chatbot comes in designs suitable across industries, that is
              Retail and Corporate banking, financial services, HR, IT Helpdesk,
              Manufacturing,Healthcare, Insurance, Hospitality,
              Telecommunication, Airlines, Retail, Education and Bespoke
              development
            </p>
          </div>
          <div className="col-lg-4 col-12 col-sm-12  mb-3">
            <h2 className="text-dark">Herbie across industries</h2>
            <h5>This chatbot does the following: </h5>
            <ul>
              <li>Rich Text Based Answers</li>
              <li>
                Can speak in multiple accents in either male or female voice
              </li>
              <li>
                Voice based Conversations where Herbie can understand colloquial
                languages, multiple dialects of Language
              </li>
              <li>
                Supports menu driven , keyword based and Conversational Dialogs
              </li>
              <li>Strong NLP Engine to support Human Like Conversation</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}
