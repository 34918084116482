import React from 'react'
import { NavLink } from 'react-bootstrap'
import Background from '../images/bg_3.jpg'
import {Link} from 'react-router-dom'

export default function NexgoPosTerminals() {
  return (
    <>
      {' '}
      <div
        className="featured-section overlay-color-2"
        style={{ backgroundImage: 'url(' + Background + ')', backgroundAttachment:'fixed' }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img
                src={require('../images/nexgo-6N-01.png')}
                alt="Image placeholder"
                className="img-fluid"
                style={{ border: 'none' }}
              />
            </div>

            <div className="col-md-6 pl-md-5">
              <span className="featured-text d-block mb-3">
                Nexgo POS Machines
              </span>
              <h2>All-in-One</h2>
              <p className="mb-3">
                You can get all payments of magnetic strip cards, IC cards,
                contactless/NFC, 1D & 2D barcodes by this smart and slim POS
                terminal.
              </p>

              <h2>Smart and Slender</h2>
              <p className="mb-3">
                N6 is the slender one of NEXGO smart POS series, it’s specially
                designed to be compact and light for easy carrying and one hand
                operation.{' '}
              </p>

              <p>
                <NavLink
                as={Link}
                  to="/nexgo-terminals"
                  className="btn btn-success btn-hover-white py-3 px-5"
                  style={{borderRadius: 0}}
                >
                  Learn More...
                </NavLink>
              </p>
            </div>
          </div>
        </div>
      </div>
      
      
    </>
  )
}
