import React, { Component } from 'react'
import NavBanner from '../components/nav_banner'
import HeadingComponent from '../components/heading_component'
import ProductComponentGridLayout from '../components/product_component_grid_layout'
import Footer from '../components/footer'

export default class Nexgo extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }
  render() {
    return (
      <>
        <NavBanner isNexgo={true} />
     
        <ProductComponentGridLayout isNexgo={true} isN5={true} isN6={true} />
        <Footer />
      </>
    )
  }
}
