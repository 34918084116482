import React from 'react'
import FeatherIcon from 'feather-icons-react'
import { NavLink } from 'react-bootstrap'
import {Link} from 'react-router-dom'

export default function NexgoPos() {
  return (
    <>
      {' '}
      <div className="featured-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 pl-md-5">
              <h2 className="text-dark">Powerful Chips</h2>
              <p className="mb-3 text-dark">
                Each NEXGO smart POS terminal is equipped with a quad-core
                processor with four independent units and a security chip,
                quad-core processor delivers better performance as more tasks
                will be accomplished simultaneously by it, at the same time,
                security chip improves the safety level of these POS terminals.
              </p>

              <h2 className="text-dark">Dual Cameras</h2>
              <p className="mb-3 text-dark">
                5MP+2MP rear and front cameras offer 2 ways of facial
                recognition. And you can explore more ways to use them.{' '}
              </p>

              {/*<p>
                <NavLink
                as={Link}
                  to="/printers-and-software"
                  className="btn btn-success btn-hover-white py-3 px-5"
                  style={{borderRadius: 0}}
                >
                  Download Specs{' '}
                  <FeatherIcon icon="download" className="pt-2" />
                </NavLink>
      </p>*/}
              </div>
            <div className="col-md-6">
              <img
                src={require('../images/6n.jpg')}
                alt="Image placeholder"
                className="img-fluid"
                style={{ border: 'none' }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="featured-section" style={{marginBottom: -70, marginTop:-50}}>
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-5 mb-sm-5">
              <img
                src={require('../images/6n_full.jpg')}
                alt="Image placeholder"
                className="img-fluid"
                style={{ border: 'none' }}
              />
            </div>

            <div className="col-md-6 pl-md-5">
              <h2 className="text-dark">5.0” HD Touchscreen</h2>
              <p className="mb-3 text-dark">
                With the large touchscreen of N6, you can get clearer customer
                e-signatures, read more information at one time, and the
                operation is simpler just like your smartphone.
              </p>

              <h2 className="text-dark">Based on Android 7.X</h2>
              <p className="mb-3 text-dark">
                Android system makes everything easy, intuitive and securer. The
                operation system on N6 is powered by Android 7.X, users can run
                different apps on it and get more value..{' '}
              </p>

              <p>
              
              </p>
            </div>
       
          </div>
        </div>
      </div>
    </>
  )
}
