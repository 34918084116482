import React, { Component } from 'react'
import ContactForms from '../components/contact_forms'
import NavBanner from '../components/nav_banner'
import Footer from '../components/footer'

export default class Contact extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <>
        <NavBanner isContact={true} />
        <ContactForms />
        <Footer />
      </>
    )
  }
}
