import React from 'react'
import FeatherIcon from 'feather-icons-react'
import { terminalData } from '../data/terminal_data'
import { Nav } from 'react-bootstrap'

export default function ProductComponentGridLayout(props) {
  return (
    <>
    
    <div className="featured-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img
                src={require('../images/terminals/n86-pos.png')}
                alt="Image placeholder"
                className="img-fluid"
                style={{ border: 'none' }}
              />
            </div>
            <div className="col-md-6">
              <h2 className="text-dark">Nexgo N86 POS Machine</h2>
              <p className="mb-3 text-dark">
                Impeccable quality and appearance make N86 irresistible. It
                delivers strong performance through the operation system powered
                by Android 9.0 and a quad-core 1.4 GHz highspeed processor. The
                innovative design combines the arts and advanced protection
                technology. And N86 is power-efficient, standby time is up to
                240 hours.
              </p>
              <p>
                <a
                  href={require('../products/N86 Datasheet.pdf')}
                  download=""
                  type='button'
                  className="btn btn-success btn-hover-white pb-3"
                  style={{borderRadius: 0}}
                >
                  Download Specs{' '}
                  <FeatherIcon icon="download" className="pt-2" />
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      

      <div className="featured-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 pl-md-5">
              <h2 className="text-dark">Nexgo N6 POS Machine</h2>
              <p className="mb-3 text-dark">
                N6 is one of the breakthrough products and NEXGO‘s first smart
                mini POS terminal. The structural design is optimized for 16.9
                mm slim body with the thinnest part only 12.6 mm. But N6 is
                powerful, runs on a quad-core processor and accepts all payment
                methods of magnetic strip cards, IC cards, contactless/NFC, 1D &
                2D barcodes.
              </p>
              <p>
                <a
                  href={require('../products/Nexgo 6N Specs.pdf')}
                  download=""
                  type='button'
                  className="btn btn-success btn-hover-white pb-3"
                  style={{borderRadius: 0}}
                >
                  Download Specs{' '}
                  <FeatherIcon icon="download" className="pt-2" />
                </a>
              </p>
            </div>
            <div className="col-md-6">
              <img
                src={require('../images/6n.jpg')}
                alt="Image placeholder"
                className="img-fluid"
                style={{ border: 'none' }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="featured-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img
                src={require('../images//terminals/nexgo_n5_1.png')}
                alt="Image placeholder"
                className="img-fluid"
                style={{ border: 'none' }}
              />
            </div>
            <div className="col-md-6">
              <h2 className="text-dark">N5 Flagship Smart POS</h2>
              <p className="mb-3 text-dark">
                N5 is the flagship product of NEXGO smart POS series with the
                most comprehensive configuration. 5.5 " HD touchscreen delivers
                the best display and operation experience. You can also choose
                fingerprint identification module to support fingerprint
                payment.
              </p>
              <p>
                <a
                  href={require('../products/Nexgo N5 Specs.pdf')}
                  download=""
                  type='button'
                  className="btn btn-success btn-hover-white pb-3"
                  style={{borderRadius: 0}}
                >
                  Download Specs{' '}
                  <FeatherIcon icon="download" className="pt-2" />
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>


      <div className="featured-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 pl-md-5">
              <h2 className="text-dark">G2 Perfomant Portable POS Terminal</h2>
              <p className="mb-3 text-dark">
                The Arm CORTEX-A7 processor built in G2 is designed for complex
                compute tasks to ensure smooth operation. G2 uses
                industry-leading dual CPU architecture to get optimal product
                performance in a compact design. In addition, 5 modes for
                communication help users to deal with different network
                environments.
              </p>
              <p>
                <a
                  href={require('../products/Nexgo G2 Specs.pdf')}
                  download=""
                  type='button'
                  className="btn btn-success btn-hover-white pb-3"
                  style={{borderRadius: 0}}
                >
                  Download Specs{' '}
                  <FeatherIcon icon="download" className="pt-2" />
                </a>
              </p>
            </div>
            <div className="col-md-6">
              <img
                src={require('../images/terminals/NEXGO-G2.jpg')}
                alt="Image placeholder"
                className="img-fluid"
                style={{ border: 'none' }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="featured-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img
                src={require('../images/terminals/Nexgo-K300-01.png')}
                alt="Image placeholder"
                className="img-fluid"
                style={{ border: 'none' }}
              />
            </div>
            <div className="col-md-6">
              <h2 className="text-dark">Nexgo K300 POS Machine</h2>
              <p className="mb-3 text-dark">
                K300 is one of the smallest and most functional miniPOS in the
                industry, it has the powerful processor, Arm Cortex-A7 with the
                speed of 1.3GHz. Creative dual CPU architecture is firstly
                applied to the miniPOS. And 5 modes for communication on the
                K300 help users to deal with different network environments.
              </p>
              <p>
                <a
                  href={require('../products/Nexgo K300 Specs.pdf')}
                  download=""
                  type='button'
                  className="btn btn-success btn-hover-white pb-3"
                  style={{borderRadius: 0}}
                >
                  Download Specs{' '}
                  <FeatherIcon icon="download" className="pt-2" />
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

      

    </>
  )
}
