export const partnersData = [
 
  {
    logo: require("../images/logos/CS_Logo_web.png"),
    company_name: "Craft Silicon",
    description:
      "provides complete financial solutions from conception to implementation all through to maintenance.",
  },

  {
    logo: require("../images/logos/nisca 2.jpg"),
    company_name: "Nisca",
    description:
      "Reliable, affordable, high quality output plastic card printers.",
  },

  {
    logo: require("../images/logos/sunsmart.png"),
    company_name: "SunSmart Chatbots",
    description:
      "provides Artificially Intelligent Chatbot with Natural Language Processing & Machine Learning capabilities. Serving your Visitors & Customers in a charming way and serves their needs tirelessly 24 x 7 x 365",
  },

  {
    logo: require("../images/logos/entrust.png"),
    company_name: "Entrust Corp",
    description: "",
  },


  {
    logo: require("../images/logos/nexgo.png"),
    company_name: "Nexgo POS Machines",
    description:
      "dedicated to research and development, manufacturing of POS terminals and providing comprehensive associated technical services for electronic payments.",
  },
  {
    logo: require("../images/logos/fargo.jpg"),
    company_name: "Fargo",
    description:
      "high-level ID card printer with optional laminator module for organizations that need high-security and high-volume ID cards &amp; badges.",
  },
  {
    logo: require("../images/logos/altronfintech-logo1.png"),
    company_name: "Altron Fintech",
    description: "",
  },

  {
    logo: require("../images/logos/marsline_logo.png"),
    company_name: "Marsline Enterprises Ltd",
    description: "",
  },

  {
    logo: require("../images/logos/idv.png"),
    company_name: "IDVISION",
    description: "",
  },
  {
    logo: require("../images/logos/startbit.png"),
    company_name: "Startbit IT Solutions Pvt. Ltd.",
    description: "",
  },
  {
    logo: require("../images/logos/sybyl.jpg"),
    company_name: "Sybyl",
    description: "",
  },
  {
    logo: require("../images/logos/mastersky.jpg"),
    company_name: "Mastersky Group",
    description: "",
  },
  /* {
      logo: require('../images/logos/Fiorano.jpg'),
      company_name: 'Fiorano',
      description:
        'WFounded in 1995 in Silicon Valley, California, Fiorano is multinational Corporation providing API-led hybrid integration technology to customers enabling interoperability, agility, profitability and new revenue streams.',
    },
      {
    logo: require("../images/logos/mastersky.jpg"),
    company_name: "Mastersky Group",
    description: "",
  },
    {
      logo: require('../images/logos/checkpoint.png'),
      company_name: 'Checkpoint',
      description:
        'Through our partnership with Emoich of South Africa, we are able to provide leading cyber security architecture of the future.',
    },
    {
      logo: require('../images/logos/datacard.png'),
      company_name: 'Datacard',
      description:
        'offers card printers and card issuance solutions for a variety of ID cards and secure financial cards.',
    }, {
      logo: require('../images/logos/kodak.png'),
      company_name: 'Kodak',
      description:
        'Kodak and Alaris branded scanners with intelligent automated features and class -leading software.',
    },
    {
      logo: require('../images/logos/dellandhp.png'),
      company_name: 'HP/Dell and Lenovo Products',
      description: "KLaptops, Desktops, Servers and Printers"
    }*/
];
