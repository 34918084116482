import React, { Fragment, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import $ from "jquery";

export default function ModalComponent(props) {
  const [validated, setValidated] = useState(false);
  const [formState, setFormState] = useState({});
  const [result, setResult] = useState("");

  const changeHandler = (event) => {
    setFormState({
      ...formState,
      [event.target.firstname]: event.target.value,
      [event.target.lastname]: event.target.value,
      [event.target.country]: event.target.value,
      [event.target.email]: event.target.value,
      [event.target.phonenumber]: event.target.value,
      [event.target.servicerequired]: event.target.value,
      [event.target.companyname]: event.target.value,
      [event.target.description]: event.target.value,
      [event.target.website]: event.target.value,
    });
  };

  const handleEnquirySubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    event.preventDefault();
    //const form = $(event.target);
    $.ajax({
      type: "POST",
      url: "http://www.websforafrica.com/cgi-bin/FormMail.pl",
      cache: false,
      data: {
        firstname: formState.firstname,
        lastname: formState.lastname,
        country: formState.country,
        email: formState.email,
        phonenumber: formState.phonenumber,
        servicerequired: formState.servicerequired,
        companyname: formState.companyname,
        description: formState.description,
        website: formState.website,
      },
      success(data) {
        setResult(data);
      },
    })
      .done(function (msg) {
        alert("Your enquiry was send successfully");
      })
      .fail(function () {
        alert("Sorry, Failed to send enquiry");
      });

    setValidated(true);
  };

  return (
    <Fragment>
      {props.isUser ? (
        <Modal
          {...props}
          centered
          size="m"
          backdropClassName="modal-backdrop"
          scrollable={true}
        >
          <Modal.Header>
            <Modal.Title>
              <div className="row container">
                <div className="col-lg-8">
                  <img
                    src={
                      Object.keys(props.user.image).length === 0
                        ? require("../images/noimage.png")
                        : props.user.image
                    }
                    alt=""
                    width="100%"
                    height="100%"
                    style={{
                      borderRadius: "6px",
                    }}
                  />
                </div>
                <div
                  className="text-start col-md-4"
                  style={{ fontSize: 18, paddingLeft: 4 }}
                >
                  <p>
                    <strong>{props.user.name}</strong>
                  </p>
                  <p>{props.user.position}</p>
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ textAlign: "start" }}>
            <p>{props.user.description}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              className="btn-success btn-hover-white"
              onClick={props.onHide}
              style={{ borderRadius: 0 }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      ) : props.isEnquiry ? (
        <Modal {...props} size="lg">
          <Form
            noValidate
            validated={validated}
            onSubmit={handleEnquirySubmit}
            method="post"
            accept-charset="ISO-8859-1"
            onsubmit="var originalCharset = document.charset; document.charset = 'ISO-8859-1'; window.onbeforeunload = function () {document.charset=originalCharset;};"
          >
            <Modal.Body>
              <Modal.Header className="mb-4">
                <Modal.Title>
                  <strong> Your Contact Details</strong>
                </Modal.Title>
              </Modal.Header>
              <Row>
                <Form.Group as={Col} md="4" className="mb-3" hasValidation>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    required
                    autoFocus
                    type="text"
                    name="firstname"
                    id="firstname"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a first name.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" className="mb-3" hasValidation>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="lastname"
                    id="lastname"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a last name.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" className="mb-3" hasValidation>
                  <Form.Label>Country</Form.Label>
                  <Form.Select
                    required
                    size="sm"
                    aria-label="Default select example"
                    name="country"
                    id="country"
                  >
                    <option>Choose...</option>
                    <option value="Zimbabwe">Zimbabwe</option>
                    <option value="Botswana">Botswana</option>
                    <option value="Kenya">Kenya</option>
                    <option value="Namibia">Namibia</option>
                    <option value="South Africa">South Africa</option>
                    <option value="United Arab Emirates">
                      United Arab Emirates
                    </option>
                    <option value="Mozambique">Mozambique</option>
                    <option value="Zambia">Zambia</option>

                    <option value="Other">Other</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please select a country.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md="4" className="mb-3" hasValidation>
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control required type="email" name="email" id="email" />
                  <Form.Control.Feedback type="invalid">
                    Please enter your email address
                  </Form.Control.Feedback>
                </Form.Group>
                {/*<Form.Group as={Col} md="4" className="mb-3" hasValidation>
                  <Form.Label>Verify Email</Form.Label>
                  <Form.Control required type="email" />
                  <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
      </Form.Group>*/}
                <Form.Group as={Col} md="4" className="mb-3" hasValidation>
                  <Form.Label>Phone/Telephone</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    name="phonenumber"
                    id="phonenumber"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter you phone number
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Modal.Header className="mb-4">
                <Modal.Title>
                  <strong> Project Details</strong>
                </Modal.Title>
              </Modal.Header>
              <Row>
                <Form.Group as={Col} md="6" className="mb-3" hasValidation>
                  <Form.Label>Type of Services you Require</Form.Label>
                  <Form.Select
                    size="sm"
                    aria-label="Default select example"
                    required
                    name="servicerequired"
                    id="servicerequired"
                  >
                    <option>Choose...</option>
                    <option value="Software Applications">
                      Software Applications
                    </option>
                    <option value="BR.Net">BR.Net</option>
                    <option value="POS Machines">POS Machines</option>
                    <option value="Card Printers">Card Printers</option>
                    <option value="Plastic Cards">Plastic Cards</option>
                    <option value="Consumables">Consumables</option>

                    <option value="Other">Other</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please select a service
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" className="mb-3" hasValidation>
                  <Form.Label>Name of Company/Institute</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="companyname"
                    id="companyname"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter your company name
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row>
                <Form.Group as={Col} md="12" className="mb-3" hasValidation>
                  <Form.Label>
                    Background & short description of services required.*
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    type="text"
                    name="description"
                    id="description"
                    required
                    rows={3}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a description
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row>
                <Form.Group as={Col} md="12" className="mb-3">
                  <Form.Label>
                    Website Address if available (Optional)
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="website"
                    id="website"
                    placeHolder="www.example.com"
                  />
                </Form.Group>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="success"
                className="btn-success btn-hover-white"
                onClick={props.onHide}
                style={{ borderRadius: 0 }}
              >
                Close
              </Button>
              <Button
                type="submit"
                value="submit"
                variant="primary"
                style={{ borderRadius: 0, color: "black" }}
              >
                Send the enquiry
              </Button>
            </Modal.Footer>

            <input
              type="hidden"
              name="recipient"
              value="info@websforafrica.com"
            />
            <input type="hidden" name="subject" value="serviceRequired" />
            <input
              type="hidden"
              name="redirect"
              value="https://www.websforafrica.com/submitted.html"
            />
            <input
              type="hidden"
              name="missing_fields_redirect"
              value="https://www.websforafrica.com/error.html"
            />
            <input type="hidden" name="required" value="email,verifyEmail" />
          </Form>
        </Modal>
      ) : props.isAccessory ? (
        <Modal
          {...props}
          centered
          size="m"
          backdropClassName="modal-backdrop"
          scrollable={true}
        >
          <Modal.Header>
            <Modal.Title>
              <div className="row container">
                <div className="col-lg-12">
                  <img
                    src={props.accessory.image_url}
                    alt=""
                    height="100%"
                    width="100%"
                    style={{ borderRadius: "6px" }}
                  />
                </div>
                <div
                  className="col-lg-8"
                  style={{ fontSize: 18, textAlign: "center", paddingLeft: 4 }}
                >
                  <p>
                    <strong>{props.accessory.name}</strong>
                  </p>
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ textAlign: "start" }}>
            <p>{props.accessory.description}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              className="btn-success btn-hover-white"
              onClick={props.onHide}
              style={{ borderRadius: 0 }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      ) : props.isPlain ? (
        <Modal
          {...props}
          centered
          size="m"
          backdropClassName="modal-backdrop"
          scrollable={true}
        >
          <Modal.Header>
            <Modal.Title>
              <div className="row container">
                <div className="col-lg-12">
                  <img
                    src={props.plainCards.image_url}
                    alt=""
                    height="100%"
                    width="100%"
                    style={{ borderRadius: "6px" }}
                  />
                </div>
                <div
                  className="col-lg-8"
                  style={{ fontSize: 18, textAlign: "center", paddingLeft: 4 }}
                >
                  <p>
                    <strong>{props.plainCards.card_name}</strong>
                  </p>
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ textAlign: "start" }}>
            <p>{props.plainCards.description}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              className="btn-success btn-hover-white"
              onClick={props.onHide}
              style={{ borderRadius: 0 }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      ) : props.isPrinted ? (
        <Modal
          {...props}
          centered
          size="m"
          backdropClassName="modal-backdrop"
          scrollable={true}
        >
          <Modal.Header>
            <Modal.Title>
              <div className="row container">
                <div className="col-lg-12">
                  <img
                    src={props.printedCards.image_url}
                    alt=""
                    height="100%"
                    width="100%"
                    style={{ borderRadius: "6px" }}
                  />
                </div>
                <div
                  className="col-lg-8"
                  style={{ fontSize: 18, textAlign: "center", paddingLeft: 4 }}
                >
                  <p>
                    <strong>{props.printedCards.card_name}</strong>
                  </p>
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ textAlign: "start" }}>
            <p>{props.printedCards.description}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              className="btn-success btn-hover-white"
              onClick={props.onHide}
              style={{ borderRadius: 0 }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      ) : undefined}
    </Fragment>
  );
}
