import React from 'react'
import FeatherIcon from 'feather-icons-react'
import { NavLink } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function CardPrinting() {
  return (
    <div className="site-section">
      <div className="container">
        <div className="row mb-5">
          <div className="col-md-12">
            <h2>Card Printers</h2>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-4 mb-lg-0">
            <div className="post-entry">
              <a href="#" className="mb-3 img-wrap">
                <img
                  src={require('../images/ds4-1.jpg')}
                  alt="Image placeholder"
                  
                  height="300px"
                  width="auto"
                />
                
              </a>
              <h3>
                <a href={require("../products/ds4-Specs.pdf")} className="text-warning">
                  Download Specs{' '}
                  <FeatherIcon icon="download" className="pt-2" />
                </a>
              </h3>
              <p>
                <strong>Entrust DS4 Direct-to-card Printer: </strong>(formerly
                Datacard® DS4™ card printer) Print Technology -
                dye-sublimation/resin thermal transfer...
              </p>
              <p>
                <NavLink
                    as={Link}
                    eventKey="/printers-and-software"
                    to="/printers-and-software"
                    className="nav-link"
                  >
                    Read More...
                  </NavLink>
              </p>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-4 mb-lg-0">
            <div className="post-entry">
              <a href="#" className="mb-3 img-wrap">
                <img
                  src={require('../images/hdp5000.png')}
                  alt="Image placeholder"
                  
                  height="300px"
                  width="auto"
                />
                
              </a>
              <h3>
                <a href={require("../products/FARGO-hdp5000-Specs.pdf")} className="text-warning">
                  Download Specs{' '}
                  <FeatherIcon icon="download" className="pt-2" />
                </a>
              </h3>
              <p>
                <strong>FARGO HDP5000: </strong>Superior print quality, higher
                reliability and durability, greater security – your photo ID
                cards will have all these qualities...
              </p>
              <p>
                <NavLink
                    as={Link}
                    eventKey="/printers-and-software"
                    to="/printers-and-software"
                    className="nav-link"
                    height="300px"
                  width="300px"
                  >
                    Read More...
                  </NavLink>
              </p>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-4 mb-lg-0">
            <div className="post-entry">
              <a href="#" className="mb-3 img-wrap">
                <img
                  src={require('../images/c53_t.png')}
                  alt="Image placeholder"
                  
                  height="300px"
                  width="auto" 
                />
                
              </a>
              <h3>
                <a href={require("../products/NiscaPR-C5350-Specs.pdf")} className="text-warning">
                  Download Specs{' '}
                  <FeatherIcon icon="download" className="pt-2" />
                </a>
              </h3>
              <p>
                <strong>NISCA PR-C5350:</strong> The printer offers brilliant
                24-bit images printed on CR80 and CR79 cards with thicknesses
                ranging from 10 to 50 mils...
              </p>
              <p>
                <NavLink
                    as={Link}
                    eventKey="/printers-and-software"
                    to="/printers-and-software"
                    className="nav-link"
                  >
                    Read More...
                  </NavLink>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
