import React from 'react'
import Background from "../images/bg/background-2462431_1920.jpg"

export default function HeaderSection() {
  return (
    <div className="block-31" style={{position: "relative"}}>
    <div className="loop-block-31 ">
      <div className="block-30 item" style={{ backgroundImage: "url(" + Background + ")", backgroundAttachment: "fixed", }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7">
              <h2 className="heading">Giving you the <strong class="text-warning">digital edge!</strong></h2>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
  )
}
