import React from 'react'
import { NavLink } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react'

export default function SoftwareSection() {
  return (
    <div>
      <div className="site-section fund-raisers">
        <div className="container">
          <div className="row mb-5">
            <div className="col-md-12">
              <h2>Software Solutions</h2>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-lg-3 mb-5">
              <div className="person-donate text-center bg-light pt-4">
                <img
                  src={require('../images/banking.png')}
                  alt="Image placeholder"
                  className="img-fluid"
                />
                <div className="donate-info">
                  {/*<h2>Banking Softwares</h2>*/}
                  <span className="time d-block mb-3">{''}</span>

                  <div className="donate-amount d-flex">
                    {/*<div className="label">{''}</div>*/}
                    <a
                      type="button"
                      className="btn px-2 py-2"
                      style={{ width: '100%', borderRadius: 0, color: 'white',backgroundColor: '#171347', cursor: 'text', fontSize: '12px'}}
                    >
                      Banking Softwares
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 mb-5">
              <div className="person-donate text-center bg-light pt-4">
                <img
                  src={require('../images/chatbot_t.png')}
                  alt="Image placeholder"
                  className="img-fluid"
                />
                <div className="donate-info">
                  {/*<h2>Chatbots</h2>*/}
                  <span className="time d-block mb-3">{''}</span>

                  <div className="donate-amount d-flex">
                    {/*<div className="label">{''}</div>*/}
                    <a
                      type="button"
                      className="btn px-2 py-2"
                      style={{ width: '100%', borderRadius: 0, color: 'white',backgroundColor: '#171347', cursor: 'text', fontSize: '12px'}}
                    >
                      Chatbots
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 mb-5">
              <div className="person-donate text-center bg-light pt-4">
                <img
                  src={require('../images/intergration.png')}
                  alt="Image placeholder"
                  className="img-fluid"
                />
                <div className="donate-info">
                  {/*<h2>Intergration Products</h2>*/}
                  <span className="time d-block mb-3">{''}</span>

                  <div className="donate-amount d-flex">
                    {/*<div className="label">{''}</div>*/}
                    <a
                      type="button"
                      className="btn px-2 py-2"
                      style={{ width: '100%', borderRadius: 0, color: 'white',backgroundColor: '#171347', cursor: 'text', fontSize: '12px'}}
                    >
                      Intergration Products
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 mb-5">
              <div className="person-donate text-center bg-light pt-4">
                <img
                  src={require('../images/cyber.png')}
                  alt="Image placeholder"
                  className="img-fluid"
                />
                <div className="donate-info">
                  {/*<h2>Cyber Security</h2>*/}
                  <span className="time d-block mb-3">{''}</span>

                  <div className="donate-amount d-flex">
                    {/*<div className="label">{''}</div>*/}
                    <a
                      type="button"
                      className="btn px-2 py-2"
                      style={{ width: '100%', borderRadius: 0, color: 'white',backgroundColor: '#171347', cursor: 'text', fontSize: '12px'}}
                    >
                      Cyber Security
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 mb-5 col-12 col-sm-12">
            <NavLink
                eventKey="/softwares"
                as={Link}
                to="/softwares"
                className="btn btn-primary px-2 py-2"
                style={{ borderRadius: 0 }}
              >
                More about softwares
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
