import React from 'react'
import { NavLink } from 'react-bootstrap'
import Background from '../images/bg_2.jpg'
import {Link} from 'react-router-dom'

export default function CardPrintingServices() {
  return (
    <div
      className="featured-donate overlay-color"
      style={{
        backgroundImage: 'url(' + Background + ')',
        backgroundAttachment: 'fixed',
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6 order-lg-2 mb-3 mb-lg-0">
            <img
              src={require('../images/bg_2.jpg')}
              alt="Image placeholder"
              className="img-fluid"
            />
          </div>
          <div className="col-lg-6 pr-lg-5">
            <span className="featured-text mb-3 d-block">
              Card Printing Service
            </span>
            <h2 style={{ fontWeight: 400 }}>
              Our services ranges from plastic card printing to embossing of
              cards. Our in-house capabilities include:
            </h2>
            <p className="mb-3">
              Any Authorised ID Card, Employee Card, Loyalty Card Printing,
              Singel Colour Printed Cards, Full Colour Printed Encoding, Barcode
              Printing, Single or Double Sided Printing, Gift Cards/Membership
              Cards, Embossed Plastic cards, Security Features on the Cards
              Including Homolmage, Holodesign, Guilloche printing, UV,
              Embossing, Smart Chip Card / Mifare Card Encoding, Adhesive PVS
              Overlays, Variable Graphics, images and text.
            </p>
            {/*<span className="donation-time mb-3">Last donation 1w ago</span>

            <span className="fund-raised d-block mb-5">
              $8,100 raised of $30,000
            </span>*/}
            <p>
              <NavLink
                as={Link}
                to="/id-cards-and-accessories"
                className="btn btn-primary btn-hover-white py-3 px-5"
                style={{ borderRadius: 0 }}
              >
                More About Cards
              </NavLink>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
