export const testimonialsData = [
  {
    logo: require("../images/logos/nmb.png"),
    companyName: "NMB Holdings Limited",
    letterText:
      "NMB enjoys a good and professional relationship with Webs for Africa Technologies and recommend them unreservedly for the supply of such products and related services.",
    author: {
      name: "Lazarus Chidoori",
      title: "(Administration Manager)",
    },
  },
  {
    logo: require("../images/logos/hac.png"),
    companyName: "High Achievers Coach Educational Center",
    letterText:
      "We are satisfied with their work, commitment to meeting timelines and the training rendered to our staff. Additionally, we appreciate their responsiveness in providing technical support whenever needed. Based on our positive experience, we have no hesitation in recommending them for similar services.",
    author: {
      name: "Dr. T Chikandiwa",
      title: "(Director of studies)",
    },
  },
  {
    logo: require("../images/logos/zsm-logo.png"),
    companyName: "Zimbabwe School of Mines",
    letterText:
      "We appreciated how communicative they were during the procuring process, and therefore we would recommend them to any other organisation that may wish to do business with them.",
    author: {
      name: "E GWAZE",
      title: "(Principal – Zimbabwe School of Mines)",
    },
  },
  {
    logo: require("../images/logos/fbc-bank.png"),
    companyName: "FBC Bank Limited",

    letterText:
      "FBC Bank, is broadly satisfied with Webs as a supplier and technical support of this line of business and we do not hesitate to recommend them in this regard.",

    author: {
      name: "Tabitha Madzikanda",
      title: "(Senior Manager- FBC Bank)",
    },
  },
  {
    logo: require("../images/logos/cimas.png"),
    companyName: "Cimas Testimonial",

    letterText:
      "One area where they really changed the game for us is in the supply of different types personalized cards for our members. They surprised us with delivering a big order even as the world was facing challenges on the supply chain due to Covid-19, ensuring data accuracy and above all at a significant lower cost than their competitors.",
    author: {
      name: "Rufaro Masunda-Jere",
      title: "(Cimas Chief Marketing Officer)",
    },
  },
  {
    logo: require("../images/logos/250px-FBC-Logo.jpg"),
    companyName: "FBC Health Insurance Testimonial",

    letterText:
      "In all the various areas of interaction, we have found Webs to be very responsive. Whenever we have a query or a challenge with our machines or software, they will give us immediate attention and help to resolve the issue in the shortest time possible. They are a reliable supplier and business partner.",
    author: {
      name: "Nigel S. Mugwendere",
      title: "(Business Development Manager – FBC Health Insurance)",
    },
  },
  {
    logo: require("../images/logos/firstmutual.png"),

    companyName: "First Mutual Testimonial",
    letterText:
      "Webs for Africa Technologies have worked with us in the supply and maintenance of the above, hence l do not hesitate recommending them to any other organisations who may wish to do business with them.",

    author: {
      name: "Barnabas Munyanyi",
      title: "(First Mutual Procurement)",
    },
  },/*
  {
    logo: require("../images/logos/generation-health-1.jpg"),

    companyName: "Generation Health Testimonial",

    letterText:
      "We appreciate how communicative they are during the card procurement process and how they keep their word.",

    author: {
      name: "Evidence Muchawaya, FM",
      title: "(For and behalf of Generation Health)",
    },
  },
  {
    logo: require("../images/logos/mycash.png"),

    companyName: "MyCash Testimonial",

    letterText:
      "We were impressed by the adherence to agreed delivery timelines and how swiftly they executed the order. The cards have been a huge win with our clients and are fully satisfied with the service received Webs For Africa is now our preferred manufacturer of cards.",

    author: {
      name: "Nyasha. I . Chapfakacha",
      title: "(MyCash Operations Projects Manager.)",
    },
  },*/
  {
    logo: require("../images/logos/bonvie.png"),

    companyName: "Bonvie Medical Aid Scheme Testimonial",
    letterText:
      "I must say, we have had a very good relationship working with Webs, they are a young company, but they have proved their mettle within such a short period of time. Moreover, the data card printer and scanners we bought from them rarely give us and problems. What impresses us the most is that they keep their word and will send updates about progress on your orders. Our members are happy with the cards we get from Webs. They are durable and attractive. It enhances our brand as a health insurance provider and their products are good value for money.",

    author: {
      name: "Barron Mazambara",
    },
  },
];

export const testimonialsData234 = [
  {
    logo: require("../images/logos/zsm-logo.png"),
    header: {
      companyName: "Zimbabwe School of Mines",
      date: "09 May 2022",
      appointement: "",
      subject: "TO WHOM IT MAY CONCERN",
    },
    letterText: {
      FP: "This letter serves to confirm that Webs for Africa Technologies has supplied us with printers and printer consumables (ID printer). We further confirm that they fulfilled the contractual obligations for delivery",
      SP: "We appreciated how communicative they were during the procuring process, and therefore we would recommend them to any other organisation that may wish to do business with them.",
      TP: "",
      clossingRemarks: "Yours faithfully,",
    },
    author: {
      name1: "E GWAZE",
      title1: "(Principal – Zimbabwe School of Mines)",
      name2: "",
      title2: "",
    },
  },
  {
    logo: require("../images/logos/fbc-bank.png"),
    header: {
      companyName: "FBC Bank Limited",
      date: "18 March 2022",
      appointement: "Dear Sir/Madam,",
      subject: "TO WHOM IT MAY CONCERN",
    },
    letterText: {
      FP: "We held a highly three-month long Proof-of-Concept (POC) with Webs for Africa Technologies (Private) Limited Webs during which the bank explored the versatility, robustness, and stability of their NEXGO Point of Sale (POS) terminals and their terminal management system. The professional engagements by both the Bank's and Webs technical teams led to the conduct of a very successful POC achieved well within the agreed timelines.",
      SP: "FBC Bank, is broadly satisfied with Webs as a supplier and technical support of this line of business and we do not hesitate to recommend them in this regard.",
      TP: "",
      clossingRemarks: "Yours faithfully,",
      onBehalfOf: "For and on behalf of FBC Bank,",
    },
    author: {
      name1: "Tabitha Madzikanda",
      title1: "(Senior Manager- FBC Bank)",
      name2: "Faideth Foto",
      title2: "(e-Banking Manager- FBC Bank)",
    },
  },
  {
    logo: require("../images/logos/cimas.png"),
    header: {
      companyName: "Cimas Testimonial",
      date: "",
      appointement: "",
      subject: "",
    },
    letterText: {
      FP: "It is with great pleasure that we recommend, Webs for Africa Technologies (Pvt) Ltd as a supplier of various type of card printers consumables, personalized cards and Kodak scanner software.",
      SP: "The assistance and support we have so far received from Webs have been excellent taking into consideration that they would constantly update on the progress of our order. Their service is dependable and professional.",
      TP: "One area where they really changed the game for us is in the supply of different types personalized cards for our members. They surprised us with delivering a big order even as the world was facing challenges on the supply chain due to Covid-19, ensuring data accuracy and above all at a significant lower cost than their competitors.",
      clossingRemarks:
        "Therefore, we recommend them to you as an efficient supplier.",
      onBehalfOf: "For and on Behalf of Cimas",
    },
    author: {
      name1: "Rufaro Masunda-Jere",
      title1: "(Cimas Chief Marketing Officer)",
      name2: "",
      title2: "",
    },
  },
  {
    logo: require("../images/logos/250px-FBC-Logo.jpg"),
    header: {
      companyName: "FBC Health Insurance Testimonial",
      date: "",
      appointement: "",
      subject: "",
    },
    letterText: {
      FP: "Our Business relationship with Webs covers a number of products including supply of Kodak scanners; ID Card printer (Fargo) and consumables. Webs also supplies us with pre-printed membership cards as well and bartender software and support and lastly labels.",
      SP: "In all the various areas of interaction, we have found Webs to be very responsive. Whenever we have a query or a challenge with our machines or software, they will give us immediate attention and help to resolve the issue in the shortest time possible. They are a reliable supplier and business partner.",
      TP: "We have experienced the same with the card they supply. Whereas other companies may take long in fulfilling orders, Webs has consistently delivered within the agreed time lines. Of note, is the fact that the cards we buy from them come with hardly an error in terms of data. The quality so far is the best we have in this market. There is a product that we distribute to our clients with both pride and confidence in terms of presentability and durability. They are also very competitively priced.",
      clossingRemarks:
        "Webs is definitely a preferred supplier and software provider.",
    },
    author: {
      name1: "Nigel S. Mugwendere",
      title1: "(Business Development Manager – FBC Health Insurance)",
      name2: "",
      title2: "",
    },
  },
  {
    logo: require("../images/logos/firstmutual.png"),
    header: {
      companyName: "First Mutual Testimonial",
      date: "",
      appointement: "",
      subject: "REF: Software, Hardware Maintenance and Consumables supply",
    },
    letterText: {
      FP: "Webs for Africa Technologies have worked with us in the supply and maintenance of the above, hence l do not hesitate recommending them to any other organisations who may wish to do business with them.",
      SP: "",
      TP: "",
      clossingRemarks: "Regards",
    },
    author: {
      name1: "Barnabas Munyanyi",
      title1: "(First Mutual Procurement)",
      name2: "",
      title2: "",
    },
  },
  {
    logo: require("../images/logos/generation-health-1.jpg"),
    header: {
      companyName: "Generation Health Testimonial",
      date: "",
      appointement: "",
      subject: "REF: Software, Hardware Maintenance and Consumables supply",
    },
    letterText: {
      FP: "Generation Health; an open medical aid Fund has been in existence for over 20 years; has in the past two years partnered with WEBS FOR AFRICA for the provision of membership cards to the Fund. WEBS FOR AFRICA offers high quality services. From the time the fund started doing business with WEBS FOR AFRICA, our costs have gone down significantly while the quality of the cards and durability of the print has improved.",
      SP: "We appreciate how communicative they are during the card procurement process and how they keep their word.",
      TP: "",
      clossingRemarks: "Regards",
    },
    author: {
      name1: "Evidence Muchawaya, FM",
      title1: "(For and behalf of Generation Health)",
      name2: "",
      title2: "",
    },
  },
  {
    logo: require("../images/logos/mycash.png"),
    header: {
      companyName: "MyCash Testimonial",
      date: "",
      appointement: "",
      subject: "MyCash, WEBS FOR AFRICA Testimonial",
    },
    letterText: {
      FP: "With reference to the above, we are writing to testify that we have worked with Webs for Africa since January, 2020. They supply us with ISO magnetic stripe pre-printed cards that are fully personalized.",
      SP: "We were impressed by the adherence to agreed delivery timelines and how swiftly they executed the order. The cards have been a huge win with our clients and are fully satisfied with the service received Webs For Africa is now our preferred manufacturer of cards.",
      TP: "Please feel free to contact us for any issues with regards to the above mentioned.",
      clossingRemarks: "Thank you",
    },
    author: {
      name1: "Nyasha. I . Chapfakacha",
      title1: "(MyCash Operations Projects Manager.)",
      name2: "",
      title2: "",
    },
  },
  {
    logo: require("../images/logos/bonvie.png"),
    header: {
      companyName: "Bonvie Medical Aid Scheme Testimonial",
      date: "",
      appointement: "",
      subject: "Testimonial",
    },
    letterText: {
      FP: "What impresses us the most is that they keep their word and will send updates about progress on your orders. Our members are happy with the cards we get from Webs. They are durable and attractive. It enhances our brand as a health insurance provider and their products are good value for money.",
      SP: "",
      TP: "Yours faithfully",
      clossingRemarks: "For and behalf of Bonvie Medical Aid Scheme)",
    },
    author: {
      name1: "Barron Mazambara",
      title1: "",
      name2: "",
      title2: "",
    },
  },
];
