import React from 'react'
import ArcodionComponent from './arcodion_component'

export default function TitleComponent(props) {
  return (
    <>
      <section
        className={props.isWelcome ? 'about_area ' : 'about_area'}
        style={{ marginBottom: '-10px', paddingTop: 120, paddingBottom: 120 }}
      >
        <div className="container">
          <div className="main_title">
            {props.isWelcome ? (
              <>
                <h2>Welcome to Webs For Africa Technologies (Pvt) Ltd</h2>
                <p>
                  Our story began in 2016 in Harare Zimbabwe where the company
                  is based and was incorporated. As a young start-up, we focused
                  on website development, design and IT consumables. We soon
                  noticed that our clients were requesting more solutions from
                  us. As the years went by, we increased our product offering
                  and we now offer a variety of digital solutions to give our
                  clients that tech edge that is required in this age. As we do
                  this, our primary motivation is to make it easier for our
                  clients to adapt to global standards and use technology to
                  enhance their effectiveness, efficiency and customer
                  experience regardless of the sector they are in. Webs has a
                  team of software developers who main focus is migration of any
                  business from analogue to digital.
                </p>
              </>
            ) /*: props.isCards ? (
              props.isPlain ? (
                <div>
                  <h2>Plain Cards</h2>
                  <p>
                    The wide range of PVC card products and the experience in
                    technology expertise us to become the team leader in the
                    industry. we have been catering both commercial as well as
                    consumer markets since inception. We have ventured into
                    partnerships with various reputed companies for scaling new
                    heights.
                  </p>
                </div>
              ) : props.isPrinted ? (
                <>
                  <h2>Preprinted Cards</h2>
                  <p>
                    Our plastic cards feature vivid, high quality four-color
                    printing on durable premium heavy weight plastic stock. We
                    can add any finishing option to meet your needs including
                    signature panels, magnetic stripe, bar-coding ,contact and
                    contactless smart cards and more.
                  </p>
                </>
              ) : (
                <>
                  <h2>ID Card Accessories</h2>
                  <p>
                    We provide the most reliable and cost effective service.
                    Webs offers different unobtrusive way to display your
                    Plastic ID Card. Buy from our large selection of ID Badge
                    Reel. Many varieties based on color, fastening options, and
                    functionality are available
                  </p>
                </>
              )
            ) */: undefined}
          </div>
          {props.isWelcome ? <ArcodionComponent /> : undefined}
        </div>
      </section>
    </>
  )
}
