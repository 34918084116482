export const terminalData = [
  {
    id: 1,
    name: 'N5 Flagship Smart POS',
    image_url: require('../images//terminals/nexgo_n5_1.png'),
    download_link: require('../products/Nexgo N5 Specs.pdf'),
    description:
      'N5 is the flagship product of NEXGO smart POS series with the most comprehensive configuration. 5.5 " HD touchscreen delivers the best display and operation experience. You can also choose fingerprint identification module to support fingerprint payment.',
  },
  {
    id:2 ,
    name: 'Nexgo N6 POS Machine',
    image_url: require('../images/nexgo-6N-01.png'),
    download_link: require('../products/Nexgo 6N Specs.pdf'),
    description:
      'N6 is one of the breakthrough products and NEXGO‘s first smart mini POS terminal. The structural design is optimized for 16.9 mm slim body with the thinnest part only 12.6 mm. But N6 is powerful, runs on a quad-core processor and accepts all payment methods of magnetic strip cards, IC cards, contactless/NFC, 1D & 2D barcodes.',
  },
  {
    id:3 ,
    name: 'Nexgo K300 POS Machine',
    image_url: require('../images/terminals/Nexgo-K300-01.png'),
    download_link: require('../products/Nexgo K300 Specs.pdf'),
    description:
      'K300 is one of the smallest and most functional miniPOS in the industry, it has the powerful processor, Arm Cortex-A7 with the speed of 1.3GHz. Creative dual CPU architecture is firstly applied to the miniPOS. And 5 modes for communication on the K300 help users to deal with different network environments.',
  },
  {
    id: 4,
    name: 'G2 Perfomant Portable POS Terminal',
    image_url: require('../images/terminals/NEXGO-G2.jpg'),
    download_link: require('../products/Nexgo G2 Specs.pdf'),
    description:
      'The Arm CORTEX-A7 processor built in G2 is designed for complex compute tasks to ensure smooth operation. G2 uses industry-leading dual CPU architecture to get optimal product performance in a compact design. In addition, 5 modes for communication help users to deal with different network environments.',
  },
  {
    id: 5,
    name: 'Nexgo N86 POS Machine',
    image_url: require('../images/terminals/n86-pos.png'),
    download_link: require('../products/N86 Datasheet.pdf'),
    description:
      'Impeccable quality and appearance make N86 irresistible. It delivers strong performance through the operation system powered by Android 9.0 and a quad-core 1.4 GHz highspeed processor. The innovative design combines the arts and advanced protection technology. And N86 is power-efficient, standby time is up to 240 hours.',
  },
]
