import React, { Component } from 'react'
import ProductsAndServices from '../components/products_and_services'
import CardPrintingServices from '../components/card_printing_services'
import Footer from '../components/footer'
import HeaderSection from '../components/header_section'
import NexgoPosTerminals from '../components/nextgo_pos_terminal'
import CardPrinter from '../components/card_printers'
import IntroComponent from '../components/intro_component'
import NexgoPos from '../components/nexgo_pos'
import SoftwareSection from '../components/software_section'
import '../css/style.css'

export default class HomePage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <>
        <HeaderSection />
        <ProductsAndServices />
        <CardPrintingServices />
        <CardPrinter />
        <NexgoPosTerminals />
        <NexgoPos />
        <SoftwareSection />
        <Footer />
      </>
    )
  }
}
