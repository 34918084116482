import React, { useState } from "react";
import { Fragment } from "react";
import { exData,teamData } from "../data/team_data";
import ModalComponent from "./modal_component";

export default function TeamComponent() {
  const [team, setTeamData] = useState(teamData);
  const [excTeam, setExcTeam] = useState(exData);
  const [modalShow, setModalShow] = useState(false);
  const [userId, setUserId] = useState(null);
  const [exUserId, setexUserId] = useState(null);

  const handleIndexClick = (event, user) => {
    event.preventDefault();
    setUserId(user.id);
    setModalShow(true);
  };

  return (
    <>
      <div className="team">
        <div className="container">
          <div className="row team_row">
            {excTeam.map((user) => (
              <>
                <div
                  key={user.id}
                  style={{ cursor: "pointer" }}
                  onClick={(event) => handleIndexClick(event, user)}
                  className="col-lg-3 col-md-6 team_col"
                >
                  <div className="team_item">
                    <div className="team_image">
                      <img
                        src={
                          Object.keys(user.image).length === 0
                            ? require("../images/noimage.png")
                            : user.image
                        }
                        alt=""
                        width="100%"
                        style={{ borderRadius: "6px" }}
                      />
                    </div>
                    <div className="team_body">
                      <div className="team_title">
                        <a type="button">{user.name}</a>
                      </div>
                      <div className="team_subtitle">{user.position}</div>
                    </div>
                  </div>
                </div>

                <Fragment>
                  {userId === user.id ? (
                    <ModalComponent
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                      key={user.id}
                      user={user}
                      isUser={true}
                    />
                  ) : (
                    <p>{""}</p>
                  )}
                </Fragment>
              </>
            ))}
          </div>
        </div>
      </div>
      <div className="team" style={{marginTop: -70}}>
        <div className="container">
          <div className="row team_row">
            {team.map((user) => (
              <>
                <div
                  key={user.id}
                  style={{ cursor: "pointer" }}
                  onClick={(event) => handleIndexClick(event, user)}
                  className="col-lg-3 col-md-6 team_col"
                >
                  <div className="team_item">
                    <div className="team_image">
                      <img
                        src={
                          Object.keys(user.image).length === 0
                            ? require("../images/noimage.png")
                            : user.image
                        }
                        alt=""
                        width="100%"
                        style={{ borderRadius: "6px" }}
                      />
                    </div>
                    <div className="team_body">
                      <div className="team_title">
                        <a type="button">{user.name}</a>
                      </div>
                      <div className="team_subtitle">{user.position}</div>
                    </div>
                  </div>
                </div>

                <Fragment>
                  {userId === user.id ? (
                    <ModalComponent
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                      key={user.id}
                      user={user}
                      isUser={true}
                    />
                  ) : (
                    <p>{""}</p>
                  )}
                </Fragment>
              </>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
