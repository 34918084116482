import React from 'react'
import FeatherIcon from 'feather-icons-react'
import Background from '../images/contact3.jpg'
import Background1 from '../images/about_3.jpg'
import CreditCard from '../images/bg/credit-card-1.png'
import CogWheels from '../images/bg/cog-wheels.jpg'
import Gadgets from '../images/bg/gadgets.jpg'
import Geo from '../images/bg/geo_bg.jpg'
import UHD from '../images/bg/uhd.jpg'
import Digi from '../images/bg/digi.jpg'
import World from '../images/bg/world.jpg'

export default function NavBanner(props) {
  return (
    <div>
      <section className="banner_area">
        <div className="banner_inner d-flex align-items-center">
          {props.isContact ? (
            <div
              className="overlay bg-parallax"
              style={{
                backgroundImage: 'url(' + Background + ')',

                top: -120,
                left: 0,
              }}
            ></div>
          ) : props.isAbout ? (
            <div
              className="overlay bg-parallax"
              style={{
                backgroundImage: 'url(' + Background1 + ')',

                top: -120,
                left: 0,
              }}
            ></div>
          ) : props.isPrinters ? (
            <div
              className="overlay bg-parallax"
              style={{
                backgroundImage: 'url(' + CogWheels + ')',
                top: -120,
                left: 0,
              }}
            ></div>
          ) : props.isCards ? (
            <div
              className="overlay bg-parallax"
              style={{
                backgroundImage: 'url(' + Geo + ')',
                top: -120,
                left: 0,
              }}
            ></div>
          ) : props.isNexgo ? (
            <div
              className="overlay bg-parallax"
              style={{
                backgroundImage: 'url(' + Gadgets + ')',
                top: -120,
                left: 0,
              }}
            ></div>
          ) : props.isBanking ? (
            <div
              className="overlay bg-parallax"
              style={{
                backgroundImage: 'url(' + UHD + ')',
                top: -120,
                left: 0,
              }}
            ></div>
          ) : undefined}
          <div className="container">
            <div className="banner_content text-center">
              {props.isAbout ? (
                <>
                  {' '}
                  <h2>About Us</h2>
                  <div className="page_link">
                    <span>Home</span>
                    <FeatherIcon icon="arrow-right" size={12} color="white" />
                    <span>About Us</span>
                  </div>{' '}
                </>
              ) : props.isContact ? (
                <>
                  <h2>Contact Us</h2>
                  <div className="page_link">
                    <span>Home</span>
                    <FeatherIcon icon="arrow-right" size={12} color="white" />
                    <span>Contact Us</span>
                  </div>
                </>
              ) : props.isPrinters ? (
                <>
                  <h2>Printers and Software</h2>
                  <div className="page_link">
                    <span>Home</span>
                    <FeatherIcon icon="arrow-right" size={12} color="white" />
                    <span>Printers and Software</span>
                  </div>
                </>
              ) : props.isCards ? (
                <>
                  <h2>ID Cards and Accessories</h2>
                  <div className="page_link">
                    <span>Home</span>
                    <FeatherIcon icon="arrow-right" size={12} color="white" />
                    <span>ID Cards and Accessories</span>
                  </div>
                </>
              ) : props.isNexgo ? (
                <>
                  <h2>Nexgo POS Terminals</h2>
                  <div className="page_link">
                    <span>Home</span>
                    <FeatherIcon icon="arrow-right" size={12} color="white" />
                    <span>Nexgo POS Terminals</span>
                  </div>
                </>
              ) : props.isBanking ? (
                <>
                  <h2>Software</h2>
                  <div className="page_link">
                    <span>Home</span>
                    <FeatherIcon icon="arrow-right" size={12} color="white" />
                    <span>Software</span>
                  </div>
                </>
              ) : undefined}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
