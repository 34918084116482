export const plainCardsData = [
  {
    id: 1,
    card_name: 'Magnetic Stripe Cards',
    image_url: require('../images/cards/plan-cards-magnetic.jpg'),
    description:
      'A magnetic stripe card is a type of PVC card with a band of magnetic material embedded into resin on the back of the card. The magnetic stripe is read by physical contact and swiping past a reading head. Magnetic stripe cards are commonly used in credit and identity cards. These cards are ideal for the encoding of data, and this makes them ideal for access control and membership schemes. They are commonplace in gyms, supermarkets, shops and many other commercial locations due to their versatility. Enhance security and functionalit Hi-Co and Lo-Co magnetic stripe Multiple lines of information encode Reduce counterfeit and duplicated card Optional signature panel',
  },
  {
    id: 2,
    card_name: 'PVC Cards',
    image_url: require('../images/cards/plan-cards-pvc.png'),
    description:
      'Composite PVC Cards offers the highest durability cards and can withstand card lamination or retransfer card printing. Provide high image quality, flexibility, and temperature durability. Stronger than standard PVC cards because they have a polyester core surrounded in PVC material',
  },
  {
    id: 3,
    card_name: 'White Adhesive Cards',
    image_url: require('../images/cards/plan-cards-adhesive.jpg'),
    description:
      'Thin White Blank Adhesive Plastic Cards are standard credit card size and feature a peel-off backing that reveals an adhesive so the card can be applied to other materials, including standard clamshell proximity cards – ideal for personalizing prox cards using a standard card printer.',
  },
  {
    id: 4,
    card_name: 'Plain Cards',
    image_url: require('../images/cards/plan-cards.png'),
    description:
      'Unlike budget PVC Cards, Fargo Cards are made of the highest grade of PVC for the most durable, long lasting cards around. Fargo’s quality manufacturing process ensures that cards are dust and burr free, helping to reduce wear and tear on printer parts.',
  },
]

export const printedCardsData = [
  {
    id: 1,
    card_name: 'Financial Card Cards',
    image_url: require('../images/cards/03.png'),
    description:
      'Financial Card is issued by commercial banks or payment mechanisms, integratd circuittechnology is used to embed IC chip into a plastic card and then package the card. Financial IC card follows the national standards of financial industry, it has the partial or complete financial function of credit consumption, transfer and settlement of accounts, cash deposit and withdraw, and also has other functions of business service and social management. EMV standards require a very secure production environment as well as security to maintain data integrity. We meet these requirements while delivering a product of high standards through our security printing services.',
  },

  {
    id: 2,
    card_name: 'Loyalty Cards',
    image_url: require('../images/cards/09.jpg'),
    description:
      'Loyalty Cards and tags are easy to use; you can carry them in your wallet or place the key tag on your key ring.',
  },
  {
    id: 3,
    card_name: 'Insurance Cards',
    image_url: require('../images/cards/10.jpg'),
    description:
      'This card helps in availing various types of medical services from hospitals and Insurance companies. This card is identified by a specific number or code, and with an individual name.',
  },
  {
    id: 4,
    card_name: 'Club Cards',
    image_url: require('../images/cards/07.png'),
    description:
      'Plastic Club Cards are becoming more popular among businesses and organizations that aim to maintain their customers.Club Cards can be found in many of the following different plastic card programs: Membership cards, Loyalty cards, Reward cards etc',
  },
  {
    id: 5,
    card_name: 'Membership Cards',
    image_url: require('../images/cards/08.png'),
    description:
      'Whether you need an annual personalized Membership Cards or on demand personalized card service, Creative Card Group can create your cards, however, you want them.Most Membership Cards include a barcode or a magnetic stripe or both. They are personalized with names and account numbers and sometimes ID photos. Most membership cards have either a vertical or horizontal slot for attaching the Membership Card to a lanyard, or clip device.',
  },
  {
    id: 6,
    card_name: 'Gift Card',
    image_url: require('../images/cards/12.png'),
    description:
      'Gift Cards are becoming one of the number one universal gifts to give friends, family, employees, etc. The Gift Card is an excellent way to bring in more customers, return credits, and promote future sales growth.',
  },
]

export const accessoriesData = [
  {
    id: 1,
    name: 'Quality goods at special prices',
    image_url: require('../images/cards/14.png'),
    description: 'We have a variety of printer and card consumables.',
  },
  {
    id: 2,
    name: 'Badge Holders',
    image_url: require('../images/cards/16.png'),
    description:
      'We supply a range of Soft & Hard PVC & plastic card holders & ID Pouches – PVC and Rigid Clear Plastic – to go with your lanyards.',
  },
  {
    id: 3,
    name: 'ID Badge Lanyards',
    image_url: require('../images/cards/15.png'),
    description:
      'Webs supply a wide range of branded lanyards suitable for events, conferences, organisations and exhibitions. These can be printed on either one or two sides and are available in a wide range of colours and fitting options. We are also one of the leading supplier of name badges and plastic holders for ID cards to use in conjunction with your lanyards. Customize your lanyard by color, length, material and functionality. If there is a lanyard you are looking for, we carry it. Lanyard attachments include: Heavy Duty Swivel, Snap Swivel Hook, Alligator Clip, Lanyard Hook, Break Away, Side Release Buckle, Mobile Phone Holder, Split Ring, Water Bottle Holder, Retractable Badge Reel & more. We can customize lanyards according to your design . Simply choose your fabric width, colour and your fitting; then send us your artwork. We have Wide choice of colored lanyards & also offer numerous attachments as per customer need.',
  },
]
