import React from "react";

export default function HeadingComponent(props) {
  return (
    <div className="container">
      <div
        className={
          props.isCards ||
          props.isBanking ||
          props.isAPI ||
          props.isHerbie ||
          props.isCyber
            ? "row mb-0"
            : "row mb-5"
        }
      >
        <div className="col-md-8 col-12 col-sm-12">
          {props.isEnvironmental ? (
            <h2>Environmental Social and Governance Standards</h2>
          ) : props.isPartnersAndClientsHeader ? (
            <h2>Our Partners and Clients</h2>
          ) : props.isTeamHeader ? (
            <h2>Our Team</h2>
          ) : props.isCards ? (
            props.isPlain ? (
              <div style={{ paddingTop: 120 }}>
                <h2>Plain Cards</h2>
              </div>
            ) : props.isPrinted ? (
              <div style={{ paddingTop: 50 }}>
                <h2>Preprinted Cards</h2>
              </div>
            ) : (
              <div style={{ paddingTop: 50 }}>
                <h2>ID Card Accessories</h2>
              </div>
            )
          ) : props.isNexgo ? (
            props.isN5 ? (
              <div style={{ paddingTop: 50 }}>
                <h2>N5 POS</h2>
              </div>
            ) : props.isN6 ? (
              <div style={{ paddingTop: 50 }}>
                <h2>N6 POS</h2>
              </div>
            ) : props.isK300 ? (
              <div style={{ paddingTop: 50 }}>
                <h2>K300 POS</h2>
              </div>
            ) : props.isG2 ? (
              <div style={{ paddingTop: 50 }}>
                <h2>G2 POS</h2>
              </div>
            ) : props.isN86 ? (
              <div style={{ paddingTop: 50 }}>
                <h2>N86 POS</h2>
              </div>
            ) : undefined
          ) : props.isSoftware ? (
            props.isBanking ? (
              <div style={{ paddingTop: 50 }}>
                <h2>Banking Softwares</h2>
              </div>
            ) : props.isAPI ? (
              <div style={{ paddingTop: 50 }}>
                <h2>API Softwares</h2>
              </div>
            ) : props.isCyber ? (
              <div style={{ paddingTop: 50 }}>
                <h2>Security Products</h2>
              </div>
            ) : props.isHerbie ? (
              <div style={{ paddingTop: 50 }}>
                <h2>Herbie AI SunSmart Global</h2>
              </div>
            ) : undefined
          ) : undefined}
          {props.hasSubtitle ? (
            props.isCards ? (
              props.isPlain ? (
                <p>
                  The wide range of PVC card products and the experience in
                  technology expertise us to become the team leader in the
                  industry. we have been catering both commercial as well as
                  consumer markets since inception. We have ventured into
                  partnerships with various reputed companies for scaling new
                  heights.
                </p>
              ) : props.isPrinted ? (
                <p>
                  Our plastic cards feature vivid, high quality four-color
                  printing on durable premium heavy weight plastic stock. We can
                  add any finishing option to meet your needs including
                  signature panels, magnetic stripe, bar-coding ,contact and
                  contactless smart cards and more.
                </p>
              ) : (
                <p>
                  We provide the most reliable and cost effective service. Webs
                  offers different unobtrusive way to display your Plastic ID
                  Card. Buy from our large selection of ID Badge Reel. Many
                  varieties based on color, fastening options, and functionality
                  are available
                </p>
              )
            ) : undefined
          ) : props.isCyber ? (
            <p>
              <h4>Our Focus on Cyber Security</h4> With increasing rate of
              cybercrime, threats have become inevitable. Webs offers solutions
              that cover the following areas. Note that some of our products
              come as a hybrid architecture that offers all protection.
            </p>
          ) : props.isHerbie ? (
            <p>
              SunSmart Global Inc (SSG Technologies LLC, SunSmart Technologies
              Private Limited) is a niche Product Company with smart products in
              the spectrum of Artificial Intelligence, Mobility and Enterprise
              Software Solutions
            </p>
          ) : undefined}
        </div>
      </div>
    </div>
  );
}
