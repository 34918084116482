export const entrustPrinterData = [
  {
    printer_name: "ENTRUST DS1 ID CARD PRINTER",
    heading: "Entrust DS1 Direct-to-card Printer",
    image: require("../images/printers/ds2_1.jpg"),
    downloadLink: require("../products/DS1-Specs.pdf"),
    description:
      "(formerly Datacard® DS1™ card printer offers direct-to-card (DTC)) Print Technology - Direct-to-card...",
  },
  {
    printer_name: "ENTRUST DS2 ID CARD PRINTER",
    heading: "Entrust DS2 Plastic ID Card Printer",
    image: require("../images/printers/ds2_1.jpg"),
    downloadLink: require("../products/DS2-Specs.pdf"),
    description:
      "(formerly DS2 Plastic ID Card Printer) Print Capabilities - Single or dual-sided, Print Technology - dye...",
  },
  {
    printer_name: "ENTRUST DS3 ID CARD PRINTER",
    heading: "Entrust DS3-2 Direct-to-card Printer",
    image: require("../images/printers/ds3_1.jpg"),
    downloadLink: require("../products/DS3-Specs.pdf"),
    description:
      "(formerly Datacardcard printer) Print Capabilities - Single or dual- sided printing, Print Technology - ...",
  },
  {
    printer_name: "ENTRUST ds4 ID CARD PRINTER",
    heading: "Entrust DS4 Direct-to-card Printer",
    image: require("../images/printers/ds4_1.jpg"),
    downloadLink: require("../products/ds4-Specs.pdf"), 
    description:
      "(formerly Datacard® DS4™ card printer) Print Technology - dye-sublimation/resin thermal transfer...",
  },
  {
    printer_name: "ENTRUST CE840 ID CARD PRINTER",
    heading: "Entrust CE840 Instant Issuance System",
    image: require("../images/printers/CE840_1.png"),
    downloadLink: require("../products/Datasheet-CE840.pdf"),
    description:
      "(formerly Datacard® CE840™ instant issuance system) Embossing - 75-character embossing/...",
  },
];

export const fargoPrinterData = [
  {
    printer_name: "HID FARGO DTC4250e PRINTER",
    image: require("../images/printers/dtc-4250e.png"),
    downloadLink: require("../products/FARGO-dtc4250e-Specs.pdf"),
    description:
      "The HID DTC4250e also features new security enhancements, such as the embedded iCLASS SE smart card encoder for encoding...",
  },
  {
    printer_name: "HID FARGO DTC1250e PRINTER",
    image: require("../images/printers/dtc1250e-f_0_5.png"),
    downloadLink: require("../products/fargo-dtc1250e-printer-nae-ds-en_2_0.pdf"),
    description:
      "Designed for small businesses, schools and local governments, the DTC1250e ID card printer is ideal for printing visually rich...",
  },
  {
    printer_name: "FARGO HDP5000 ID CARD PRINTER",
    image: require("../images/hdp_t.png"),
    downloadLink: require("../products/FARGO-hdp5000-Specs.pdf"),
    description:
      "Superior print quality, higher reliability and durability, greater security – your photo ID printers will have all these qualities...",
  },
];

export const niscaPrinterData = [
  {
    printer_name: "NISCA PR-C101 ID CARD PRINTER",
    image: require("../images/printers/NiscaPR-C101.png"),
    downloadLink: require("../products/NiscaPR-C101-Spec.pdf"),
    description:
      "The NISCA PR-C101 single-sided printer provides a simple and affordable out-of-the-box solution for high quality, high durability...",
  },
  {
    printer_name: "NISCA PR-C151 ID CARD PRINTER",
    image: require("../images/printers/NiscaPR-C151-02.png"),
    downloadLink: require("../products/NiscaPR-C151-Spec.pdf"),
    description:
      "The printer offers brilliant 24-bit images printed on CR80 cards with thicknesses ranging from 20 mil – 50 mil (.25mm-1.27mm) (10...",
  },
  {
    printer_name: "NISCA PR-C5350 ID CARD PRINTER",
    image: require("../images/c53_t.png"),
    downloadLink: require("../products/NiscaPR-C5350-Specs.pdf"),
    description:
      "The printer offers brilliant 24-bit images printed on CR80 and CR79 cards with thicknesses ranging from 10 to 50 mils. The PR5350...",
  },
  {
    printer_name: "NISCA PR-L151 ID CARD PRINTER",
    image: require("../images/printers/prl151-discontinued-large.png"),
    downloadLink: require("../products/nisca-prl151-brochure-4.pdf"),
    description:
      "The PR-L151 Heat Roller (laminator) is designed to easily attach. The unit offers high-speed lamination for standard CR-80 cards...",
  },
];

export const cimPrintersData = [
  {
    printer_name: "SUNLIGHT SATURN",
    image: require("../images/printers/cim-sunlight-saturn-removebg-preview.png"),
    downloadLink: require("../products/cim-sunlight-saturn-eng_71221.pdf"),
    description:
      "SUNLIGHT SATURN is a fast, versatile and affordable card and badge printer with state-of-the-art features and technology. It can be...",
  },
];
