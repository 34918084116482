import React, { Component } from 'react'
import ProductComponent from '../components/product_component'
import NavBanner from '../components/nav_banner'
import Footer from '../components/footer'

export default class PrintersSoftware extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <>
        <NavBanner isPrinters={true} />
        <ProductComponent isPrinter={true} isEntrust={true} isFargo={false} />
        <ProductComponent isPrinter={true} isEntrust={false} isFargo={true} />
        <ProductComponent isPrinter={true} isNisca={true} isEntrust={false} isFargo={false} />
        <ProductComponent isPrinter={true} isNisca={false} isEntrust={false} isFargo={false} />
        <ProductComponent isPrinter={false} isSoftware={true} />
        <Footer />
      </>
    )
  }
}
