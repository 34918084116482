import React, { useRef, useEffect, useState } from 'react'
import { Button, Dropdown, Image, NavDropdown, NavLink } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react'
import useWindowDimensions from '../utilities/dimensions'
import ModalComponent from './modal_component'

export default function NavigationBar() {
  const [navBackground, setNavBackground] = useState(false)
  const [modalShow, setModalShow] = useState(false)
  const { width } = useWindowDimensions()
  const navRef = useRef()
  navRef.current = navBackground
  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 0
      if (navRef.current !== show) {
        setNavBackground(show)
      }
    }
    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])
  return (
    <nav
      className={
        navBackground
          ? 'navbar navbar-expand-lg navbar-light ftco_navbar bg-light ftco-navbar-light py-0'
          : 'navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light '
      }
      style={{
        transition: '1s ease',
        top: navBackground ? 0 : width <= 991.98 ? 0 : 10,
      }}
      id="ftco-navbar"
    >
      <div className="container">
        <NavLink className="navbar-brand" eventKey="/" as={Link} to="/">
          <Image
            src={
              navBackground
                ? require('../images/logo_dark.png')
                : require('../images/logo_white.png')
            }
            height={width <= 600 ? 15 : 30}
          />
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#ftco-nav"
          aria-controls="ftco-nav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <FeatherIcon
            as="span"
            className="oi oi-menu"
            style={{ paddingTop: 10 }}
            icon="menu"
          />{' '}
          Menu
        </button>

        <div className="collapse navbar-collapse" id="ftco-nav">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <NavLink
                eventKey="/"
                as={Link}
                to="/"
                className={navBackground ? 'nav-link-fixed' : 'nav-link'}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item submenu dropdown">
              <NavLink
                as={Link}
                to=""
                eventKey=""
                className={navBackground ? 'nav-link-fixed' : 'nav-link'}
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Products
              </NavLink>
              <ul className="dropdown-menu">
                <li className="nav-item">
                  <NavLink
                    as={Link}
                    eventKey="/printers-and-software"
                    to="/printers-and-software"
                    className="nav-link"
                  >
                    ID Card Printers & Software
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    as={Link}
                    eventKey="/id-cards-and-accessories"
                    to="/id-cards-and-accessories"
                    className="nav-link"
                  >
                    ID Cards and Accessories
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    as={Link}
                    eventKey="/nexgo-terminals"
                    to="/nexgo-terminals"
                    className="nav-link"
                  >
                    Nexgo Pos
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    as={Link}
                    eventKey="/softwares"
                    to="/softwares"
                    className="nav-link"
                  >
                    Softwares
                  </NavLink>
                </li>

                
                {/*<li className="nav-item">
                  <NavDropdown
                    title="Software"
                    id="nav-dropdown"
                    drop="end"
                    menuVariant="dark"
                  >
                    <NavDropdown.Item as={Link} to="/banking-softwares" eventKey="banking-softwares">
                    Banking Softwares
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/" eventKey="4.2">
                      Another action
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/" eventKey="4.3">
                      Something else here
                    </NavDropdown.Item>
                    
                    <NavDropdown.Item as={Link} to="/" eventKey="4.4">
                      Separated link
                    </NavDropdown.Item>
                  </NavDropdown>
          </li>*/}
              </ul>
            </li>
            {/*
            <li className="nav-item submenu dropdown">
              <NavLink
                as={Link}
                eventKey="/software"
                to="/software"
                className={navBackground ? 'nav-link-fixed' : 'nav-link'}
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Software
              </NavLink>
              <ul className="dropdown-menu">
                <li className="nav-item">
                  <NavLink as={Link} eventKey="/" to="/" className="nav-link">
                    Banking Softwares
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink as={Link} eventKey="/" to="/" className="nav-link">
                    Intergration Products 
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink as={Link} eventKey="/" to="/" className="nav-link">
                    Chatbots
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink as={Link} eventKey="/" to="/" className="nav-link">
                    Cyber Security Solutions
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink as={Link} eventKey="/" to="/" className="nav-link">
                    Custom Software Development
                  </NavLink>
                </li>
              </ul>
            </li>
           
            <li className="nav-item">
              <NavLink
                eventKey="/partners"
                as={Link}
                to="/partners"
                className={navBackground ? 'nav-link-fixed' : 'nav-link'}
              >
                Partners
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                eventKey="/clients"
                as={Link}
                to="/clients"
                className={navBackground ? 'nav-link-fixed' : 'nav-link'}
              >
                Clients
              </NavLink>
          </li>*/}
            <li className="nav-item">
              <NavLink
                eventKey="/about-us"
                as={Link}
                to="/about-us"
                className={navBackground ? 'nav-link-fixed' : 'nav-link'}
              >
                About Us
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                eventKey="/contact"
                as={Link}
                to="/contact"
                className={navBackground ? 'nav-link-fixed' : 'nav-link'}
              >
                Contact Us
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                as={Link}
                type="button"
                className={navBackground ? 'nav-link-fixed' : 'nav-link'}
              >
                <Button
                  className="text-white text-align-center"
                  variant="warning"
                  size="sm"
                  style={{ textTransform: 'none', letterSpacing: 0,borderRadius:0 }}
                  onClick={() => setModalShow(true)}
                >
                  Send an enquiry
                </Button>
              </NavLink>

              <ModalComponent
                show={modalShow}
                onHide={() => setModalShow(false)}
                isUser={false}
                isEnquiry={true}
              />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
