import React, { Component } from 'react'
import NavBanner from '../components/nav_banner'
import Footer from '../components/footer'

import HeadingComponent from '../components/heading_component'
import ProductComponent from '../components/product_component'

export default class CardAccessories extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <>
        <NavBanner  isCards={true}/>
        <HeadingComponent hasSubtitle={true} isCards={true} isPlain={true} />
        <ProductComponent isCards={true} isPlainCards={true}  />
        <HeadingComponent hasSubtitle={true} isCards={true} isPlain={false} isPrinted={true} />
        <ProductComponent isCards={true} isPlainCards={false} isPrintedCards={true} />
        <HeadingComponent hasSubtitle={true} isCards={true} isPlain={false} isPrinted={false} />
        <ProductComponent isCards={true} isPlainCards={false} isPrintedCards={false} />
        <Footer />
      </>
    )
  }
}
