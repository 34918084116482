import React, { useState } from "react";
import { Fragment } from "react";
import {
  entrustPrinterData,
  fargoPrinterData,
  niscaPrinterData,
  cimPrintersData,
} from "../data/printers_data";
import { softwareData } from "../data/software_data";
import FeatherIcon from "feather-icons-react";
import { Nav } from "react-bootstrap";
import {
  accessoriesData,
  plainCardsData,
  printedCardsData,
} from "../data/cards_data";
import LinesEllipsis from "react-lines-ellipsis";
import ModalComponent from "./modal_component";

export default function ProductComponent(props) {
  const [modalShow, setModalShow] = useState(false);
  const [accessoryId, setAccessoryId] = useState(null);
  const [plainCardId, setPlainCardId] = useState(null);
  const [printedCardsId, setPrintedCardsId] = useState(null);

  const handleClickAccessory = (event, accessory) => {
    event.preventDefault();
    setAccessoryId(accessory.id);
    setModalShow(true);
  };

  const handleClickPlainCards = (event, plain_cards) => {
    event.preventDefault();
    setPlainCardId(plain_cards.id);
    setModalShow(true);
  };

  const handleClickPrintedCards = (event, printed_cards) => {
    event.preventDefault();
    setPrintedCardsId(printed_cards.id);
    setModalShow(true);
  };

  return (
    <div className={props.isCards ? "mb-5" : "site-section"}>
      <div className="container">
        <div className="row mb-3">
          <div className="col-md-12">
            {props.isPrinter ? (
              props.isEntrust ? (
                <h2>Entrust Printers</h2>
              ) : props.isFargo ? (
                <h2>Fargo Printers</h2>
              ) : props.isNisca ? (
                <h2>Nisca Printers</h2>
              ) : (
                <h2>CIM Printers</h2>
              )
            ) : props.isSoftware ? (
              <h2>Card Printing Software Products</h2>
            ) : undefined}
          </div>
        </div>

        <div className="row">
          {props.isPrinter
            ? props.isEntrust
              ? entrustPrinterData.map((entrust) => (
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-4 mb-lg-0">
                    <div className="post-entry">
                      <div className="mb-3 img-wrap">
                        <img
                          src={entrust.image}
                          alt="Image placeholder"
                          className="img-fluid"
                        />
                      </div>
                      <p>
                        <h5>{entrust.printer_name}</h5>
                        <strong>{entrust.heading}: </strong>
                        {entrust.description}
                      </p>
                      <p>
                        <Nav.Link href={entrust.downloadLink} download="">
                          Read More{" "}
                          <FeatherIcon icon="download" className="pt-2" />
                        </Nav.Link>
                      </p>
                    </div>
                  </div>
                ))
              : props.isFargo
              ? fargoPrinterData.map((fargo) => (
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-4 mb-lg-0">
                    <div className="post-entry">
                      <a href="#" className="mb-3 img-wrap">
                        <img
                          src={fargo.image}
                          alt="Image placeholder"
                          className="img-fluid"
                        />
                      </a>
                      <p>
                        <strong>{fargo.printer_name}: </strong>
                        {fargo.description}
                      </p>
                      <p>
                        <a href={fargo.downloadLink} download="">
                          Read More{" "}
                          <FeatherIcon icon="download" className="pt-2" />
                        </a>
                      </p>
                    </div>
                  </div>
                ))
              : props.isNisca
              ? niscaPrinterData.map((nisca) => (
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-4 mb-lg-0">
                    <div className="post-entry">
                      <a href="#" className="mb-3 img-wrap">
                        <img
                          src={nisca.image}
                          alt="Image placeholder"
                          className="img-fluid"
                        />
                      </a>
                      <p>
                        <strong>{nisca.printer_name}</strong>
                        {nisca.description}
                      </p>
                      <p>
                        <a href={nisca.downloadLink} download="">
                          Read More{" "}
                          <FeatherIcon icon="download" className="pt-2" />
                        </a>
                      </p>
                    </div>
                  </div>
                ))
              : cimPrintersData.map((cim) => (
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-4 mb-lg-0">
                    <div className="post-entry">
                      <a href="#" className="mb-3 img-wrap">
                        <img
                          src={cim.image}
                          alt="Image placeholder"
                          className="img-fluid"
                        />
                      </a>
                      <p>
                        <strong>{cim.printer_name}</strong>
                        {cim.description}
                      </p>
                      <p>
                        <a href={cim.downloadLink} download="">
                          Read More{" "}
                          <FeatherIcon icon="download" className="pt-2" />
                        </a>
                      </p>
                    </div>
                  </div>
                ))
            : props.isSoftware
            ? softwareData.map((software_data) => (
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-4 mb-lg-0">
                  <div className="post-entry">
                    <a href="#" className="mb-3 img-wrap">
                      <img
                        src={software_data.image}
                        alt="Image placeholder"
                        className="img-fluid"
                        style={{ height: 230 }}
                      />
                    </a>
                    <h3>{software_data.company_name}</h3>
                    <p>
                      <strong>{software_data.software_name}: </strong>
                      {software_data.description}
                    </p>
                    <p>
                      <a href={software_data.downloadLink} download="">
                        Read More{" "}
                        <FeatherIcon icon="download" className="pt-2" />
                      </a>
                    </p>
                  </div>
                </div>
              ))
            : props.isCards
            ? props.isPlainCards
              ? plainCardsData.map((plain_cards) => (
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-4 mb-lg-0">
                    <div className="post-entry">
                      <a href="#" className="mb-3 img-wrap">
                        <img
                          src={plain_cards.image_url}
                          alt="Image placeholder"
                          className="img-fluid"
                          style={{ height: 230 }}
                        />
                      </a>
                      <h3>{plain_cards.card_name}</h3>
                      <p>
                        <LinesEllipsis
                          text={plain_cards.description}
                          maxLine="3"
                          ellipsis="..."
                          trimRight
                          basedOn="letters"
                        />
                      </p>
                      <p>
                        <a
                          href="#"
                          type="button"
                          onClick={(event) =>
                            handleClickPlainCards(event, plain_cards)
                          }
                        >
                          Read More...
                        </a>
                      </p>

                      <Fragment>
                        {plainCardId === plain_cards.id ? (
                          <ModalComponent
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            key={plain_cards.id}
                            plainCards={plain_cards}
                            isUser={false}
                            isPlain={true}
                          />
                        ) : (
                          <p>{""}</p>
                        )}
                      </Fragment>
                    </div>
                  </div>
                ))
              : props.isPrintedCards
              ? printedCardsData.map((printed_cards) => (
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-4 mb-lg-0">
                    <div className="post-entry">
                      <a href="#" className="mb-3 img-wrap">
                        <img
                          src={printed_cards.image_url}
                          alt="Image placeholder"
                          className="img-fluid"
                          style={{ height: 230 }}
                        />
                      </a>
                      <h3>{printed_cards.card_name}</h3>
                      <p>
                        <LinesEllipsis
                          text={printed_cards.description}
                          maxLine="3"
                          ellipsis="..."
                          trimRight
                          basedOn="letters"
                        />
                      </p>
                      <p>
                        <a
                          href="#"
                          type="button"
                          onClick={(event) =>
                            handleClickPrintedCards(event, printed_cards)
                          }
                        >
                          Read More...
                        </a>
                      </p>

                      <Fragment>
                        {printedCardsId === printed_cards.id ? (
                          <ModalComponent
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            key={printed_cards.id}
                            printedCards={printed_cards}
                            isUser={false}
                            isPrinted={true}
                          />
                        ) : (
                          <p>{""}</p>
                        )}
                      </Fragment>
                    </div>
                  </div>
                ))
              : accessoriesData.map((accessory) => (
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-4 mb-lg-0">
                    <div className="post-entry">
                      <a href="#" className="mb-3 img-wrap">
                        <img
                          src={accessory.image_url}
                          alt="Image placeholder"
                          className="img-fluid"
                          style={{ height: 230 }}
                        />
                      </a>
                      <h3>{accessory.name}</h3>
                      <p>
                        <LinesEllipsis
                          text={accessory.description}
                          maxLine="3"
                          ellipsis="..."
                          trimRight
                          basedOn="letters"
                        />
                      </p>
                      <p>
                        <a
                          href="#"
                          type="button"
                          onClick={(event) =>
                            handleClickAccessory(event, accessory)
                          }
                        >
                          Read More...
                        </a>
                      </p>

                      <Fragment>
                        {accessoryId === accessory.id ? (
                          <ModalComponent
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            key={accessory.id}
                            accessory={accessory}
                            isUser={false}
                            isAccessory={true}
                          />
                        ) : (
                          <p>{""}</p>
                        )}
                      </Fragment>
                    </div>
                  </div>
                ))
            : undefined}
        </div>
      </div>
    </div>
  );
}
