import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import useWindowDimensions from "../utilities/dimensions";
import { NavLink } from "react-router-dom";

export default function ProductsAndServices() {
  const { width } = useWindowDimensions;
  return (
    <>
      <section
        className="mt-4 mb-5 py-5"
        style={{
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-2 col-0 col-sm-0"></div>
            <div className="col-md-8 col-12 col-sm-12">
              <img
                src={require("../images/logo_dark.png")}
                height={width <= 600 ? 15 : 40}
                className="mb-4"
              />

              <p>
                Our primary goal is to make it easier for our clients,
                regardless of industry, to use technology to improve their
                effectiveness, efficiency, and customer service and adapt to
                global standards as we aid them in computerizing their business
                processes, provide frameworks to follow through these cycles and
                to guarantee more prominent productivity.
              </p>
            </div>
            <div className="col-md-2 col-0 col-sm-0"></div>
          </div>
        </div>
      </section>

      <div
        className="site-section fund-raisers bg-light"
        style={{
          marginTop: -35,
          /*background: 'rgba(209,219,231, 0.1',*/
        }}
      >
        <div className="container">
          <div className="row mb-5">
            <div className="col-md-12">
              <h2>Products and Services</h2>
              <p>
                A fintech company which provides digital payment solutions
                through a well-crafted ecosystem.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 block-11">
              <div className="nonloop-block-11 carousel2 card-group">
                <div className="card fundraise-item mr-lg-3">
                  <a href="#">
                    <img
                      className="card-img-top"
                      src={require("../images/cards/plan-cards-pvc.png")}
                      alt="Image placeholder"
                    />
                  </a>
                  <div className="card-body">
                    <h3 className="card-title">
                      <a href="#">Card Printing</a>
                    </h3>
                    <p className="card-text">
                      Card Printing : Loyalty, ID Cards – Staff, Student etc,
                      Bank Cards – CPA, NFC, Visa, Membership Cards.
                    </p>
                    {/*<span className="donation-time mb-3 d-block">Last donation 1w ago</span>*/}

                    <span className="fund-raised d-block">
                      <NavLink className="text-warning" eventKey="/id-cards-and-accessories" to="/id-cards-and-accessories">
                        Learn More...
                      </NavLink>
                    </span>
                  </div>
                </div>

                <div className="card fundraise-item mr-lg-3">
                  <a href="#">
                    <img
                      className="card-img-top"
                      src={require("../images/n86-02.jpg")}
                      alt="Image placeholder"
                    />
                  </a>
                  <div className="card-body">
                    <h3 className="card-title">
                      <a href="#">POS Machines</a>
                    </h3>
                    <p className="card-text">
                      Nexgo POS machines which are high-tech payment terminals,
                      smart and slim and point of sale hardware and software.
                    </p>
                    {/*<span className="donation-time mb-3 d-block">Last donation 1w ago</span>*/}

                    <span className="fund-raised d-block">
                    <NavLink className="text-warning" eventKey="/nexgo-terminals" to="/nexgo-terminals">
                        Learn More...
                      </NavLink>
                    </span>
                  </div>
                </div>

                <div className="card fundraise-item ">
                  <a href="#">
                    <img
                      className="card-img-top"
                      src={require("../images/software.png")}
                      alt="Image placeholder"
                      height="80%"
                      width="auto"
                    />
                  </a>
                  <div className="card-body" style={{ marginTop: -55 }}>
                    <h3 className="card-title">
                      <a href="#">Software Solutions</a>
                    </h3>
                    <p className="card-text">
                      Software solutions: POS Terminal Management System, Core
                      banking, micro-finance, switching and electronic payments
                      for the financial sector.
                    </p>
                    {/*<span className="donation-time mb-3 d-block">Last donation 1w ago</span>*/}

                    <span className="fund-raised d-block">
                    <NavLink className="text-warning" eventKey="/softwares" to="/softwares">
                        Learn More...
                      </NavLink>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
