import React, { Component } from 'react'
import CarouselComponent from '../components/carousel_component'
import CyberComponent from '../components/cyber_component'
import CyberGridComponent from '../components/cyber_grid_component'
import Footer from '../components/footer'
import HeadingComponent from '../components/heading_component'
import NavBanner from '../components/nav_banner'

export default class Softwares extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <>
        <NavBanner isBanking={true} />
        <div className="bg-light">
          <HeadingComponent isSoftware={true} isHerbie={true} />
          <CyberGridComponent />
        </div>
        <HeadingComponent isSoftware={true} isBanking={true} />
        <CarouselComponent isSoftware={true} isBanking={true} />
        <HeadingComponent isSoftware={true} isAPI={true} />
        <CarouselComponent isSoftware={true} isBanking={false} />
        <HeadingComponent isSoftware={true} isCyber={true} />
        <CarouselComponent isSoftware={true} isSecurityProducts={true} />
        <br />
        <br />

        <Footer />
      </>
    )
  }
}
