import React from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import CarouselComponent from './carousel_component'

function TabsComponent() {
  return (
    <div className="container mb-5">
      <Tabs
        defaultActiveKey="partners"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="partners" title="Our Partners">
          <CarouselComponent isTestimonials={false} />
        </Tab>
        <Tab eventKey="testimonials" title="Our Clients Testimonials">
          <CarouselComponent isTestimonials={true} />
        </Tab>
      </Tabs>
    </div>
  )
}

export default TabsComponent
