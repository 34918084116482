import React from 'react'
import FeatherIcon from 'feather-icons-react'
export default function ArcodionComponent() {
  return (
    <div className="row about_inner mt-lg-5">
      <div className='col-lg-1'></div>
      <div className="col-lg-10 col-12 col-sm-12">
        <div className="accordion" id="accordionExample">
          <div className="card">
            <div className="card-header" id="headingOne">
              <button
                className="btn btn-link"
                type="button"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Our Vision
                <i className="lnr lnr-chevron-down">
                  <FeatherIcon icon="chevron-down" />
                </i>
                <i className="lnr lnr-chevron-up">
                  <FeatherIcon icon="chevron-up" />
                </i>
              </button>
            </div>

            <div
              id="collapseOne"
              className="collapse show"
              aria-labelledby="headingOne"
              data-parent="#accordionExample"
            >
              <div className="card-body">
                An Africa where businesses and organisations are fully
                digitalised and are abreast with technology advances.
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header" id="headingTwo">
              <button
                className="btn btn-link collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Our Mission
                <i className="lnr lnr-chevron-down">
                  <FeatherIcon icon="chevron-down" />
                </i>
                <i className="lnr lnr-chevron-up">
                  <FeatherIcon icon="chevron-up" />
                </i>
              </button>
            </div>
            <div
              id="collapseTwo"
              className="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordionExample"
            >
              <div className="card-body">
                At Webs, it is our mission to assist our clients in enhancing
                their competitive edge and customer satisfaction through
                cutting-edge technology solutions and to migrate them from
                analogue to digital interfaces.
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header" id="headingThree">
              <button
                className="btn btn-link collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                Our Values
                <i className="lnr lnr-chevron-down">
                  <FeatherIcon icon="chevron-down" />
                </i>
                <i className="lnr lnr-chevron-up">
                  <FeatherIcon icon="chevron-up" />
                </i>
              </button>
            </div>
            <div
              id="collapseThree"
              className="collapse"
              aria-labelledby="headingThree"
              data-parent="#accordionExample"
            >
              <div className="card-body">
                At Webs we place great emphasis on : INTEGRITY,
                RELIABILITY, TEAMWORK, and EXCELLENCE in all we do.
              </div>
            </div>
          </div>
          {/*<div className="card">
            <div className="card-header" id="headingfour">
              <button
                className="btn btn-link collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapsefour"
                aria-expanded="false"
                aria-controls="collapsefour"
              >
                Warning
                <i className="lnr lnr-chevron-down">
                  <FeatherIcon icon="chevron-down" />
                </i>
                <i className="lnr lnr-chevron-up">
                  <FeatherIcon icon="chevron-up" />
                </i>
              </button>
            </div>
            <div
              id="collapsefour"
              className="collapse"
              aria-labelledby="headingfour"
              data-parent="#accordionExample"
            >
              <div className="card-body">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris.
              </div>
            </div>
  </div>*/}
        </div>
      </div>
      <div className='col-lg-1'></div>
      {/*<div className="col-lg-6 col-12 col-sm-12 mt-5 mt-sm-0">
        <iframe
          title="https://www.youtube.com/embed/PW_xdM2gDpA"
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/PW_xdM2gDpA"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>*/}
    </div>
  )
}
