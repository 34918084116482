import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import NavigationBar from './components/navbar'
import HomePage from './views/home'
import Contact from './views/contact'
import About from './views/about'
import PrintersSoftware from './views/printers_software'
import CardAccessories from './views/card_accessories'
import Nexgo from './views/nexgo'
import Softwares from './views/softwares'

export default function App() {
  return (
    <div className="App">
      <Router>
        <NavigationBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/printers-and-software" element={<PrintersSoftware />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/id-cards-and-accessories" element={<CardAccessories />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/nexgo-terminals" element={<Nexgo />} />
          <Route path="/softwares" element={<Softwares />} />
        </Routes>
      </Router>
    </div>
  )
}
