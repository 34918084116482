export const softwareData = [
  {
    software_name: 'AsureID',
    company_name: 'Software by HID',
    image: require('../images/logos/assureid.jpg'),
    downloadLink: require('../products/Asureid-Specs.pdf'),
    description:
      'From the basic Asure ID Solo to the advanced Asure ID Exchange - and everything in between - the Asure ...',
  },
  {
    software_name: 'CARDPRESSO',
    company_name: 'Card Personalization Software',
    image: require('../images/logos/cardpresso.jpg'),
    downloadLink: require('../products/Cardpresso_Specs.pdf'),
    description:
      'CardPresso is designed to make card production easy. Graphic design and personalization tools are included...',
  },
]

export const bankingData = [
  {
    software_name: 'Craft Silicon - Agency Banking',
    description:
      'Residents in remote places are often left relatively untouched by the banking revolution. This is where agents play a crucial role in enabling banking access for all. Agency Banking, a customized software makes the job easy for agents to drive higher penetration levels.',
    downloadLink: require('../products/CS-Product-Sheet-Agency-banking.pdf'),
  },
  {
    software_name: 'Craft Silicon - Anti-Money Laundering',
    description:
      'The flip side of the digital revolution is the rise in cybercrimes, especially money laundering. Thus, it is prudent for banks to proactively adopt an Anti-Money Laundering (AML) software. AML can red flag security threats & ensure regulatory compliance.',
    downloadLink: require('../products/CS-Product-Sheet-AML.PDF'),
  },
  {
    software_name: 'Craft Silicon - Bank Realm Switch',
    description:
      'Banking is a highly competitive space that requires a constant connection with customers. Bankers Realm Electronic Funds Transfer (BREFT) Switch offers a continuous channel that functions on autopilot mode to strengthen customer relations.',
    downloadLink: require('../products/CS-Product-sheet-BR-Electronic-Switch.pdf'),
  },
  {
    software_name: 'Craft Silicon - Corporate Connect',
    description:
      'Corporate Connect enables corporates to directly handle bulk payments and other transactions, eliminating the need to physically visit the branch.',
    downloadLink: require('../products/CS-Product-sheet-Corporate-connect.pdf'),
  },
  {
    software_name: 'Craft Silicon - Mobile Banking Solution- ELMA',
    description:
      'Technology today has revolutionised the way business is conducted across the globe, and consumers now expect and demand real-time communication systems that empower them to make timely decisions and do business on the go. Financial institutions are responding to these evolving requirements and demands from their customers and stakeholders, by implementing solutions that enable ease of communication and conducting business. Mobile phones have rapidly made inroads into every aspect of our daily lives and businesses are leveraging on this channel to service their customers ELMA is an integrated mobile commerce & lifestyle platform, connecting to a wallet’s back end (financial institution) & a diverse collection of third-party service providers to allow for access to banking services and facilitation of payments.',
    downloadLink: require('../products/CS-Product-sheet-ELMA.PDF'),
  },
  {
    software_name: 'Craft Silicon - eVoucher Solution',
    description:
      'To stay competitive, it is imperative that Agri Finance embrace cutting edge technology. This translates into ushering in a digitalised experience for end-customers through use of eVouchers.',
    downloadLink: require('../products/CS-Product-Sheet-evoucher.pdf'),
  },
  {
    software_name: 'Craft Silicon - Remote Account Opening Solution',
    description:
      'Remote Account Opening is an agile solution that facilitates opening of customer accounts remotely/digitally via the banks availed channels with proper KYC validation done through our integration to third-party data systems. This solution allows financial institutions to acquire new customers in an efficient manner with customers capturing their KYC documents (as per the policies of the financial institution) & the same is stored securely by the system under their profile.',
    downloadLink: require('../products/CS-Product-sheet-REMOTE-ACCOUNT-OPENING.PDF'),
  },
  {
    software_name: 'Craft Silicon - Virtual Lending',
    description:
      'Virtual Lending, a one of its kind tech solution facilitates loan sanction, payments and bank products access to customers through a unique credit scoring model. The credit score segments customers into different buckets based on credit track record.',
    downloadLink: require('../products/CS-Product-sheet-Virtual-banking.pdf'),
  },
  {
    software_name: 'Craft Silicon - Loan Management Solution',
    description:
      'Timely loans and credit access often are a lifeline for SMEs and individuals to tide over a working capital shortfall or meet sudden capital expenditure. This is where Loan Management Solution can make all the difference. This solution is a one-stop-shop to approve and track the entire loan portfolio.',
    downloadLink: require('../products/CS-Product-sheet-LOAN-MANAGEMENT-SOLUTION.PDF'),
  },
  {
    software_name: 'Craft Silicon - Omnichannel Banking',
    description:
      'Omnichannel banking enables banks to continuously engage with customers through virtual channels and multiple touch points.',

    downloadLink: require('../products/CS-Product-sheet-OMNI-CHANNEL.PDF'),
  },
  {
    software_name: 'Bankers Realm Electronic Funds Transfer Switch',
    description:
      'Banking is a highly competitive space that requires a constant connection with customers. Bankers Realm Electronic Funds Transfer (BREFT) Switch offers a continuous channel that functions on autopilot mode to strengthen customer relations.',
    downloadLink: require('../products/CS-Product-sheet-BR-Electronic-Switch.pdf'),
  },
  {
    software_name:
      'Craft Silicon - Bankers Realm Investment Management Solution',
    description:
      'Bankers Realm Investment Management Solution (BRIMS) offers comprehensive investment management tools along with detailed reports and analysis. BRIMS makes the investment job easier for fund managers.',
    downloadLink: require('../products/CS-Product-Sheet-Fund-management.pdf'),
  },
  {
    software_name: 'Craft Silicon - SME Banking Toolkit',
    description:
      'An innovative digital banking solution that delivers both financial services and business insights for Small and Medium Enterprises (SMEs) to manage their business on the go. SMEs can manage their daily operations, access banking services, connect with subject matter experts within the banks for advice and also access the market place of all registered members. The solution is adopted by the bank, who then extend it to their SME customers.',
    downloadLink: require('../products/CS-Product-sheet-SME-BANKING-TOOLKIT.PDF'),
  },
  {
    software_name: 'Craft Silicon - WhatsApp Chat Business APIs',
    description:
      'Chat Business APIs enable the integration of all WhatsApp business accounts with your internal customer communication system. One can leverage WhatsApp’s robust business messaging channel to send all customer communication with end-to-end encryption and also connect additional apps.',
    downloadLink: '',
  },
  {
    software_name: 'Fiorano ISO 20022 Accelerator (FIA) Infrastructure',
    description:
      'In todays world, businesses need to be digital at the core. They require world- class infrastructure to support universal interoperability for all heterogeneous internal and external systems including partner channels to benefit from automation and next-generation tools.',
    downloadLink: require('../products/fiorano_brochure_HIP.PDF'),
  },
  {
    software_name: 'Fiorano SWIFT',
    description:
      "Fiorano Software, a leading provider of enterprise integration middleware, API management and banking solutions, today announced confirmation by the Society for Worldwide Interbank Financial Telecommunications (SWIFT) of Fiorano's support for SWIFT's Cross-Border Payments and Reporting Plus (CBPR+) message specifications. Fiorano is now one of a select set of worldwide vendors whose solution has cleared certification requirements by SWIFT for CBPR+ readiness.",
    downloadLink: require('../products/Fiorano-SWIFT.pdf'),
  },
]

export const apiData = [
  {
    software_name: 'FIORANO API Managements',
    description:
      'Exposing interfaces to unique corporate data creates value that many companies have difficulty unlocking on their own. By exposing internal enterprise data and application functionality to external applications on mobile devices, consoles and affiliate web sites, an organization can transform its business into an extensible platform. Enterprises require ways to publish APIs to external developers, to interact with customers over new mobility and social channels, innovate and evolve ways to reach new customers through partner and third-party applications and exploit the exponential explosion of client end-points to drive end-user business. Fiorano API Management offers a range of capabilities, including protocol transformation, standards-based access management, version management, API rate limiting, metering (analytics) and mobile backend-as-a-service (MBaaS). Backend and third-party web services can be transformed into easily consumable APIs, governed by self-service policies. Available both as an on-premise platform and as a cloud service, Fiorano API Management is particularly well suited for enterprises that need deep integration capabilities to build APIs on top of existing applications. It provides contextual analysis and visibility into API initiatives and associated digital assets to help drive developer and use.',
    downloadLink: require('../products/FioranoAPI_Management_DataSheet_Web.pdf'),
  },
  {
    software_name: 'FIORANO Hybrid Integration Platform',
    description:
      'Fiorano ISO 20022 Accelerator components are proven to support both short-term and tactical message and interface implementation projects as well as providing an ideal underlying technology framework for a more long-term and micro-service centric approach to ISO 20022 powered transformation. The Fiorano Hybrid Integration Platform (HIP) combines benefits of the elasticity of cloud and security of on-premises systems. It allows organizations to rapidly deploy a light- weight, powerful and scalable platform as their IT infrastructure backbone, enabling asynchronous information exchange across the extended enterprise to drive meaningful business outcomes.',
    downloadLink: require('../products/Fiorano ISO20022_Brochure.pdf'),
  },
]

export const m360Data = [
  {
    software_name: 'Merchant 360 POS Platform',
    description:
      'Merchant 360 has: Remote Key Injection Terminal Management System Merchant OnboardingAcquirer & Merchant Portal Digital Receipt & Accounting, Push Commands to terminals and Reports',
    downloadLink: require('../products/m360.pdf'),
  },
  {
    software_name: 'Terminal Management System',
    description:
      'POS Management Platform comprises of: Secured Remote Key Injection (Build on Soft HSM),Terminal Management System (TMS),Quick Merchant and Terminals Onboarding,Geo-Location, Geo-Fencing, Push Commands,Digital Receipts & Accounting,Web and Mobile Portals for Acquirer, Regions & Merchants.',
    downloadLink: '',
  },
]

export const cyberData = [
  {
    heading: 'Real-time Threat Prevention: ',
    downloadLink: require('../products/cp-infinity.pdf'),
    description:
      'Protection against APTs and unknown zero day malware, using real-time sandboxing; ransomware protection; and anti-bot technologies, powered by integrated, real-time cloud-based threat intelligence and machine learning for identifying new threats.',
  },
  {
    heading: 'Advanced Network Security: ',
    downloadLink: require('../products/cp-infinity.pdf'),
    description:
      'The most advanced firewall, intrusion prevention and application control, supporting networks of any size—from branch offices to global enterprises, and across both private and public cloud security offerings',
  },
  {
    heading: 'Cloud Security: ',
    downloadLink: require('../products/cp-infinity.pdf'),
    description:
      'Advanced threat prevention security in public, private and hybrid cloud, and SDN environments, with micro-segmentation for east-west traffic control inside the cloud.',
  },
  {
    heading: 'Mobile Security: ',
    downloadLink: require('../products/cp-infinity.pdf'),
    description:
      'Malware prevention on iOS and Android mobile devices, rogue network identification, secure containers, data protection and document encryption, and EMM integration',
  },
  {
    heading: 'Data Protection: ',
    downloadLink: require('../products/cp-infinity.pdf'),
    description:
      'Anti-ransomware for known and unknown ransomware, data protection and seamless document encryption, browser security, a fully integrated endpoint protection suite and security forensics.',
  },
  {
    heading: 'Integrated Security & Threat Management: ',
    downloadLink: require('../products/cp-infinity.pdf'),
    description:
      'A unified security management environment supporting multi-device, multi-domain and multi-admin management, with complete threat visibility supporting collection, correlation and attack analysis, and reporting tools for compliance and audit.',
  },
  {
    heading: 'Security Services: ',
    downloadLink: require('../products/cp-infinity.pdf'),
    description:
      'Real-time security updates (ThreatCloud), software updates, hardware maintenance, 24x7 support and maintenance.',
  },
]


export const herbieAi = [
  {
    heading: 'About Herbie.ai',
    description: 'Complete Customer Experience with integrated approach – Human Web Chat, Virtual Assistant – Voice & text'
  },
  {
    heading: 'Herbie across industries',
    description: 'The chatbot comes in designs suitable across industries, that is Retail and Corporate banking, financial services, HR, IT Helpdesk, Manufacturing,Healthcare, Insurance, Hospitality, Telecommunication, Airlines, Retail, Education and Bespoke development'
  },{
    heading: 'Herbie across industries',
    description: 'This chatbot does the following: Rich Text Based Answers, Can speak in multiple accents in either male or female voice, Voice based Conversations where Herbie can understand colloquial languages, multiple dialects of Language, Supports menu driven , keyword based and Conversational Dialogs, Strong NLP Engine to support Human Like Conversation'
  }
]