import React, { useState } from 'react'
import FeatherIcon from 'feather-icons-react'
import { Form } from 'react-bootstrap'
import $ from 'jquery'

export default function ContactForms() {
  const [validated, setValidated] = useState(false)
  const [formState, setFormState] = useState({})
  const [result, setResult] = useState('')

  const changeHandler = (event) => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
      [event.target.email]: event.target.value,
      [event.target.subject]: event.target.value,
      [event.target.message]: event.target.value,
    })
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    }

    event.preventDefault()
    //const form = $(event.target);
    $.ajax({
      type: 'POST',
      url: 'http://www.websforafrica.com/cgi-bin/FormMail.pl',
      cache: false,
      data: {
        name: formState.name,
        email: formState.email,
        subject: formState.subject,
        message: formState.message,
      },
      success(data) {
        setResult(data)
      },
    }).done(function(msg) { 
      alert("Your email was send successfully");
    })
    .fail(function(){
      alert("Sorry, Failed to send email");
    })


    setValidated(true)
  }

  return (
    <>
      <section className="contact_area p_120 mb-5 pb-5 pt-5">
        <div className="container pt-lg-5">
          <div class="row mb-lg-5">
            <div className="col-lg-6">
              <div className="contact_info">
                <div className="info_item">
                  <i className="lnr lnr-home">
                    <FeatherIcon icon="home" color="black" />
                  </i>

                  <h3>
                    <strong> Zimbabwe</strong>
                  </h3>
                  <p>2nd Floor, Block 5, Celestial Park, Borrowdale, Harare</p>
                </div>
                <div className="info_item">
                  <i className="lnr lnr-phone-handset">
                    <FeatherIcon icon="phone" color="black" />
                  </i>
                  <h6>
                    <span href="#">
                      +263 242 886 475 / +263 242 886 476 / +263 718 787 350/1
                    </span>
                  </h6>
                  <p>Mon to Fri 8am to 5pm</p>
                </div>
                <div className="info_item">
                  <i className="lnr lnr-envelope">
                    <FeatherIcon icon="mail" color="black" />
                  </i>
                  <h6>
                    <a href="mailto:info@websforafrica.com?subject=Enquiry Email from the Website ">
                      info@websforafrica.com
                    </a>
                  </h6>
                  <p>Send us your query anytime!</p>
                </div>

                <div className="info_item">
                  <i className="lnr lnr-envelope">
                    <FeatherIcon icon="link" color="black" />
                  </i>
                  <h6>
                    <a target='_blank' href="https://websforafrica.atlassian.net/servicedesk/customer/portal/3">
                      Service Desk
                    </a>
                  </h6>
                  <p>Send us a query on our service desk!</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mt-5 mt-sm-0 mb-5 mb-sm-0">
              <div className="contact_info">
                <div className="info_item">
                  <i className="lnr lnr-home">
                    <FeatherIcon icon="home" color="black" />
                  </i>
                  <h3>
                    <strong> Zambia</strong>
                  </h3>
                  <p>
                    Norwich Insurance Brokers Offices, 6884 cnr of Katimamulilo
                    & Bende road, Olympia, Lusaka, Zambia
                  </p>
                </div>
                <div className="info_item">
                  <i className="lnr lnr-phone-handset">
                    <FeatherIcon icon="phone" color="black" />
                  </i>
                  <h6>
                    <span href="#">+260 962 198 98 963 / +260 73 406 588</span>
                  </h6>
                  <p>Mon to Fri 8am to 5pm</p>
                </div>
                <div className="info_item">
                  <i className="lnr lnr-envelope">
                    <FeatherIcon icon="mail" color="black" />
                  </i>
                  <h6>
                    <a href="mailto:infozm@websforafrica.com?subject=Enquiry Email from the Website ">
                      infozm@websforafrica.com
                    </a>
                  </h6>
                  <p>Send us your query anytime!</p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <Form
                className="row contact_form"
                id="contactForm"
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
                method="post"
                accept-charset="ISO-8859-1"
                onsubmit="var originalCharset = document.charset; document.charset = 'ISO-8859-1'; window.onbeforeunload = function () {document.charset=originalCharset;};"
              >
                <div className="col-md-6">
                  <div
                    id="mapBox"
                    className="mapBox"
                    data-lat="40.701083"
                    data-lon="-74.1522848"
                    data-zoom="13"
                    data-info="PO Box CT16122 Collins Street West, Victoria 8007, Australia."
                    data-mlat="40.701083"
                    data-mlon="-74.1522848"
                  >
                    <iframe
                      title="https://www.google.com/maps/d/u/0/embed?mid=1PXAm00jLmJArlu75DKoHQY_mj19OwyQ&ehbc=2E312F"
                      src="https://www.google.com/maps/d/u/0/embed?mid=1PXAm00jLmJArlu75DKoHQY_mj19OwyQ&ehbc=2E312F"
                      width="100%"
                      height="90%"
                    ></iframe>
                  </div>
                </div>
                <div className="col-md-6">
                  <Form.Group className="form-group" hasValidation>
                    <Form.Control
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      required
                      value={formState.name || ''}
                      onChange={changeHandler}
                      placeholder="Enter your name"
                      autoFocus
                    />
                    <Form.Control.Feedback type="invalid">
                      Please choose a name.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="form-group" hasValidation>
                    <Form.Control
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      required
                      value={formState.email || ''}
                      onChange={changeHandler}
                      placeholder="Enter email address"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid email.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="form-group" hasValidation>
                    <Form.Control
                      type="text"
                      className="form-control"
                      id="subject"
                      name="subject"
                      required
                      value={formState.subject || ''}
                      onChange={changeHandler}
                      placeholder="Enter Subject"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a subject.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="form-group" hasValidation>
                    <Form.Control
                      as="textarea"
                      className="form-control"
                      name="message"
                      id="message"
                      rows={5}
                      required
                      value={formState.message || ''}
                      onChange={changeHandler}
                      placeholder="Enter Message"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter your message.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div className="col-md-12 text-lg-right">
                    <button
                      type="submit"
                      value="submit"
                      className="btn submit_btn"
                      style={{ borderRadius: 0 }}
                      onClick={handleSubmit}
                    >
                      Send Message
                    </button>
                  </div>

                  <input
                    type="hidden"
                    name="recipient"
                    value="info@websforafrica.com"
                  />
                  <input type="hidden" name="subject" value="serviceRequired" />
                  <input
                    type="hidden"
                    name="redirect"
                    value="https://www.websforafrica.com/submitted.html"
                  />
                  <input
                    type="hidden"
                    name="missing_fields_redirect"
                    value="https://www.websforafrica.com/error.html"
                  />
                  <input
                    type="hidden"
                    name="required"
                    value="email,verifyEmail"
                  />
                </div>
              </Form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
