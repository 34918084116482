import React from 'react'
import { Carousel, Row, Col } from 'react-bootstrap'
import { partnersData } from '../data/partners_data'
import { testimonialsData } from '../data/testimonials_data'
import FeatherIcon from 'feather-icons-react'
import OwlCarousel from 'react-owl-carousel-autoheight'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import {
  apiData,
  bankingData,
  cyberData,
  herbieAi,
  siberData,
} from '../data/software_data'
import LinesEllipsis from 'react-lines-ellipsis'

export default function CarouselComponent(props) {
  return (
    <section className="about_area">
      {props.isTestimonials ? (
        <Carousel
          nextIcon={
            <FeatherIcon icon="arrow-right" size={24} color={'black'} />
          }
          prevIcon={<FeatherIcon icon="arrow-left" size={24} color={'black'} />}
          indicators={false}
          prevLabel=""
          nextLabel=""
          interval={5000}
          pause="hover"
        >
          {testimonialsData.map((testimonial, index) => (
            <Carousel.Item className="pb-5 pt-5 container" key={index}>
              <div className="row">
                <div className="col-lg-1 col-sm-1 col-1"></div>
                <div className="col-lg-10 col-sm-10 col-10">
                  <img
                    src={
                      Object.keys(testimonial.logo).length === 0
                        ? require('../images/noimage.png')
                        : testimonial.logo
                    }
                    alt=""
                    height={80}
                    className="mb-5"
                  />

                  <h4>{testimonial.companyName}</h4>

                  <p>{testimonial.letterText}</p>

                  <h5 style={{fontWeight:'bold'}}>{testimonial.author.name}</h5>

                  <p>{testimonial.author.title}</p>
                </div>
                <div className="col-lg-1 col-sm-1 col-1"></div>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      ) : props.isSoftware ? (
        props.isBanking ? (
          <Carousel
            nextIcon={
              <FeatherIcon icon="arrow-right" size={24} color={'black'} />
            }
            prevIcon={
              <FeatherIcon icon="arrow-left" size={24} color={'black'} />
            }
            indicators={false}
            prevLabel=""
            nextLabel=""
            interval={5000}
            pause="hover"
          >
            {bankingData.map((software, index) => (
              <Carousel.Item className="pb-4 pt-4 container" key={index}>
                <div className="row">
                  <div className="col-lg-1 col-sm-1 col-1"></div>
                  <div className="col-lg-10 col-sm-10 col-10">
                    <h3 style={{ color: 'black' }}>{software.software_name}</h3>
                    <p className="mb-3 text-dark">
                      <LinesEllipsis
                        text={software.description}
                        maxLine="3"
                        ellipsis="..."
                        trimRight
                        basedOn="letters"
                      />
                    </p>
                    <p>
                      <a
                        href={software.downloadLink}
                        download=""
                        type="button"
                        className="btn btn-success btn-hover-white pb-3"
                        style={{borderRadius: 0}}
                      >
                        Download Product Sheet{' '}
                        <FeatherIcon icon="download" className="pt-2" />
                      </a>
                    </p>
                  </div>
                  <div className="col-lg-1 col-sm-1 col-1"></div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        ) : (
          <Carousel
            nextIcon={
              <FeatherIcon icon="arrow-right" size={24} color={'black'} />
            }
            prevIcon={
              <FeatherIcon icon="arrow-left" size={24} color={'black'} />
            }
            indicators={false}
            prevLabel=""
            nextLabel=""
            interval={5000}
            pause="hover"
          >
            {apiData.map((api, index) => (
              <Carousel.Item className="pb-4 pt-4 container" key={index}>
                <div className="row">
                  <div className="col-lg-1 col-sm-1 col-1"></div>
                  <div className="col-lg-10 col-sm-10 col-10">
                    <h3 style={{ color: 'black' }}>{api.software_name}</h3>
                    <p className="mb-3 text-dark">
                      <LinesEllipsis
                        text={api.description}
                        maxLine="3"
                        ellipsis="..."
                        trimRight
                        basedOn="letters"
                      />
                    </p>
                    <p>
                      <a
                        href={api.downloadLink}
                        download=""
                        type="button"
                        className="btn btn-success btn-hover-white pb-3"
                        style={{borderRadius: 0}}
                      >
                        Download Product Sheet{' '}
                        <FeatherIcon icon="download" className="pt-2" />
                      </a>
                    </p>
                  </div>
                  <div className="col-lg-1 col-sm-1 col-1"></div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        )
      ) : props.isSecurityProducts ? (
        <Carousel
          nextIcon={
            <FeatherIcon icon="arrow-right" size={24} color={'black'} />
          }
          prevIcon={<FeatherIcon icon="arrow-left" size={24} color={'black'} />}
          indicators={false}
          prevLabel=""
          nextLabel=""
          interval={5000}
          pause="hover"
        >
          {cyberData.map((cyber, index) => (
            <Carousel.Item className="pb-4 container" key={index}>
              <div className="row">
                <div className="col-lg-1 col-sm-1 col-1"></div>
                <div className="col-lg-10 col-sm-10 col-10">
                  <h3 style={{ color: 'black' }}>{cyber.heading}</h3>
                  <p className="mb-3 text-dark">
                    <LinesEllipsis
                      text={cyber.description}
                      maxLine="3"
                      ellipsis="..."
                      trimRight
                      basedOn="letters"
                    />
                  </p>
                  <p>
                    <a
                      href={cyber.downloadLink}
                      download=""
                      type="button"
                      className="btn btn-success btn-hover-white pb-3"
                      style={{borderRadius: 0}}
                    >
                      Download Product Sheet{' '}
                      <FeatherIcon icon="download" className="pt-2" />
                    </a>
                  </p>
                </div>
                <div className="col-lg-1 col-sm-1 col-1"></div>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      ) : props.isHerbie ? (
        <Carousel
          nextIcon={
            <FeatherIcon icon="arrow-right" size={24} color={'black'} />
          }
          prevIcon={<FeatherIcon icon="arrow-left" size={24} color={'black'} />}
          indicators={false}
          prevLabel=""
          nextLabel=""
          interval={5000}
          pause="hover"
        >
          {herbieAi.map((herbie, index) => (
            <Carousel.Item className="pb-4 container" key={index}>
              <div className="row">
                <div className="col-lg-1 col-sm-1 col-1"></div>
                <div className="col-lg-10 col-sm-10 col-10">
                  <h3 style={{ color: 'black' }}>{herbie.heading}</h3>
                  <p className="mb-3 text-dark">
                    <LinesEllipsis
                      text={herbie.description}
                      maxLine="3"
                      ellipsis="..."
                      trimRight
                      basedOn="letters"
                    />
                  </p>
                 
                </div>
                <div className="col-lg-1 col-sm-1 col-1"></div>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      ) : (
        <OwlCarousel
          className="owl-theme"
          items={4}
          loop={true}
          margin={30}
          autoplay={true}
          autoplaySpeed={1000}
          autoplayTimeout={3000}
          autoplayHoverPause={true}
          slideTransition="linear"
          autoHeight={true}
        >
          {partnersData.map((partners, index) => (
            <div className=" pt-5" key={index}>
              <img
                src={
                  Object.keys(partners.logo).length === 0
                    ? require('../images/noimage.png')
                    : partners.logo
                }
                alt=""
                height={60}
                className="mb-5"
              />
            </div>
          ))}
        </OwlCarousel>
      )}
    </section>
  )
}
