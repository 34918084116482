import React from 'react'
import { Link } from 'react-router-dom'
import { NavLink } from 'react-bootstrap'
import Background from '../images/footer_1.png'
import FeatherIcon from 'feather-icons-react'

export default function Footer() {
  return (
    <footer className="footer">
      <div
        className="container"
        style={{
         /* backgroundImage: 'url(' + Background + ')',*/
          
        }}
      >
        <div className="row py-0">
          <div className="col-md-6 col-lg-4">
            <h3 className="heading-section">About Us</h3>
            <p className="mb-4" style={{color:'white'}}>
              Our story began in 2016 in Harare Zimbabwe where the company is
              based and was incorporated. As a young start-up, we focused on
              website development, design and IT consumables. We soon noticed
              that our clients were requesting more solutions from us. As the
              years went by, we increased our product offering and we now offer
              a variety of digital solutions to give our clients that tech edge
              that is required in this age.
            </p>
            <p>
              <NavLink
                eventKey="/about-us"
                as={Link}
                to="/about-us"
                className="btn btn-primary px-2 py-2"
                style={{borderRadius: 0}}
              >
                More About Us
              </NavLink>
            </p>
          </div>
          <div className="col-md-6 col-lg-4">
            <h3 className="heading-section">Quick Links</h3>
            <div className="block-21 d-flex mb-4">
              <figure className="mr-3">
                <img
                  src={require('../images/cards/union-cards.png')}
                  alt=""
                  className="img-fluid"
                />
              </figure>
              <div className="text">
                <h3 className="heading">
                  <NavLink eventKey="/printers-and-software" as={Link} to="/printers-and-software">
                    Cards and Printers
                  </NavLink>
                </h3>
              </div>
            </div>

            <div className="block-21 d-flex mb-4">
              <figure className="mr-3">
                <img
                  src={require('../images/nx1.png')}
                  alt=""
                  className="img-fluid"
                />
              </figure>
              <div className="text">
                <h3 className="heading">
                  <NavLink eventKey="/nexgo-terminals" as={Link} to="/nexgo-terminals">
                    Nexgo POS Terminals
                  </NavLink>
                </h3>
              </div>
            </div>

            <div className="block-21 d-flex mb-4">
              <figure className="mr-3">
                <img
                  src={require('../images/software.png')}
                  alt=""
                  className="img-fluid"
                />
              </figure>
              <div className="text">
                <h3 className="heading">
                  <NavLink eventKey="/softwares" as={Link} to="/softwares">
                    Software Products
                  </NavLink>
                </h3>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="block-23 justify-content-start">
              <h3 className="heading-section">Contact Info</h3>
              <ul>
                <li>
                  <span className="text">
                    2nd Floor, Block 5, Celestial Park, Borrowdale, Harare,
                    Zimbabwe
                  </span>
                </li>
                <li>
                  
                    <span className="text">
                      +263 242 886 475, +263 242 886 476, +263 718 787 350/1
                    </span>
                  
                </li>
                <li>
                  <NavLink
                    eventKey="/"
                    
                    href="mailto:info@websforafrica.com?subject=Enquiry Email from the Website "
                    style={{ paddingLeft: 0 }}
                  >
                    <span className="text">info@websforafrica.com</span>
                  </NavLink>
                </li>
                <li>
                  <span className="text">
                    Monday &mdash; Friday 8:00am - 5:00pm
                  </span>
                </li>
                <div className="row col-7 mt-3">
                  <div className="col-3" style={{padding: 0}}>
                    <a href="https://www.facebook.com/websforafrica">
                      <FeatherIcon icon="facebook" />
                    </a>
                  </div>
                  <div className="col-3" style={{padding: 0}}>
                    <a href="https://www.instagram.com/webs_for_africa/">
                      <FeatherIcon icon="instagram" />
                    </a>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
